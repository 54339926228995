import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import Feedback from './Feedback';
import FormGroup from './FormGroup';
import { Label } from 'reactstrap';
import styled from 'styled-components';

export const RadioGroupContext = createContext();

export const useRadioGroup = radioValue => {
  const { setFieldValue } = useFormikContext();
  const { name: groupName, value = '', groupOnChange, ...rest } = useContext(RadioGroupContext);

  const setValue = () => {
    setFieldValue(groupName, radioValue);
    if (groupOnChange) {
      groupOnChange(radioValue);
    }
  };

  return { setValue, value: value === radioValue, ...rest };
};

const RadioGroup = ({
  name,
  children,
  label,
  onChange: groupOnChange,
  inline = false,
  className,
  helpText,
  groupClassName,
  ...rest
}) => {
  const [field, metadata] = useField(name);
  const classes = classNames(
    className,
    'form-control border-0 p-0 h-auto',
    metadata.touched ? 'is-touched' : 'is-untouched',
    metadata.touched && metadata.error && 'is-invalid',
  );

  const legend = label ? <Label>{label}</Label> : '';
  const defaultValue = rest.defaultValue;

  return (
    <RadioGroupContext.Provider value={{ ...field, groupOnChange, metadata, inline, defaultValue }}>
      <FormGroup tag="fieldset" for={name} {...rest} className={`${groupClassName ? groupClassName : ''}`}>
        {legend}
        {helpText && (
          <div className="mb-3">
            <small>{helpText}</small>
          </div>
        )}
        <Div className={classes + ' radio'} data-testid={`radio-items-${name}`}>
          {children}
        </Div>
        <Feedback name={name} />
      </FormGroup>
    </RadioGroupContext.Provider>
  );
};

const Div = styled.div`
  &.basic {
    label {
      padding-left: 10px;
    }
    .form-check {
      margin-right: 30px;
    }
  }
  &.fill {
    > div {
      flex: 1 1 auto !important;
    }
  }
  &:not(.basic) {
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 22.5%;
      margin-bottom: 13px;
    }
    label {
      border: 1px solid #ced4da;
      padding: 10px;
      width: 100%;
      text-align: center;
      margin-left: -18px;
      height: 100%;
    }
    input[type='radio'] {
      visibility: hidden;
    }
    input[type='radio']:checked + label {
      border: 1px solid #000;
      color: #000;
    }
  }
`;

RadioGroup.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.node,
  onChange: PropTypes.func,
  inline: PropTypes.bool,
  className: PropTypes.string,
};

export default RadioGroup;
