import { SM } from 'containers/App/constants';

const routes = [
  {
    path: '/',
    pageComponent: 'containers/dashboard',
  },
  {
    path: '/profiles',
    pageComponent: 'containers/profiles/views',
  },
  {
    path: '/reports',
    pageComponent: 'containers/reports',
  },
  {
    path: '/overview/service-center-loading',
    pageComponent: 'containers/sc-loading',
  },
  {
    path: '/report/monthly-vehicles-throughput-summary',
    pageComponent: 'containers/monthly/monthly-veh-tp-summary.js',
  },
  {
    path: '/report/monthly-vehicles-throughput-details',
    pageComponent: 'containers/monthly/monthly-veh-tp-detail.js',
  },
  {
    path: '/report/monthly-vehicles-throughput-by-sa-summary',
    pageComponent: 'containers/monthly/monthly-veh-tp-by-sa-sum.js',
  },
  {
    path: '/report/monthly-vehicles-throughput-by-sa-details',
    pageComponent: 'containers/monthly/monthly-veh-tp-by-sa-details.js',
  },
  {
    path: '/report/ytd-vehicles-throughput-by-sa',
    pageComponent: 'containers/monthly/ytd-veh-tp-sa',
  },
  {
    path: '/report/monthly-avg-servicing-veh-throughput',
    pageComponent: 'containers/monthly/monthly-avg-servicing-veh-tp',
  },
  {
    path: '/overview/appointment-report/:id',
    pageComponent: 'containers/appointments/pages/dashboard-vehicles',
  },
  {
    path: '/overview/attendance/technician',
    pageComponent: 'containers/attendance/dashboard-attendance',
  },
  {
    path: '/overview/attendance/sa',
    pageComponent: 'containers/attendance/dashboard-attendance-sa',
  },
  {
    path: '/report/absence/summary/sa',
    pageComponent: 'containers/attendance/report-attendance-sa-summary',
  },
  {
    path: '/report/absence/summary/technician',
    pageComponent: 'containers/attendance/report-attendance-summary',
  },
  {
    path: '/report/absence/detail/sa',
    pageComponent: 'containers/attendance/report-attendance-sa-detail',
  },
  {
    path: '/report/absence/detail/technician',
    pageComponent: 'containers/attendance/report-attendance-detail',
  },
  {
    path: '/report/exit-interview-summary',
    pageComponent: 'containers/exit-interviews/report-summary',
  },
  {
    path: '/report/exit-interview-details',
    pageComponent: 'containers/exit-interviews/report-details',
  },
  {
    path: '/report/carryover',
    menu: true,
    name: 'Report',
    id: 'Notifications',
    accessible: [SM],
    pageComponent: 'containers/appointments/pages/report-carryover',
  },
  {
    path: '/overview/vehicles-per-sa',
    pageComponent: 'containers/sa/vehicle-per-sa',
  },
  {
    path: '/overview/appointment-per-sa',
    pageComponent: 'containers/sa/appt-per-sa',
  },
  {
    path: '/overview/appointment-for-sa',
    pageComponent: 'containers/sa/appt-for-sa',
  },
  {
    path: '/overview/appointment-for-sa/:id',
    pageComponent: 'containers/sa/appt-for-sa',
  },
  {
    path: '/overview/appointments-for-all-sa',
    pageComponent: 'containers/sa/for_all_sa',
  },
  {
    path: '/overview/exit-interview/:id',
    pageComponent: 'containers/exit-interviews/single-question',
  },
  {
    path: '/overview/exit-interview/by-sa/:id',
    pageComponent: 'containers/exit-interviews/by-service-advisor',
  },
];

export default routes;
