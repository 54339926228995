/*
 * App Global Actions
 */

import {
  USER_LOGIN,
  USER_LOGIN_ERROR,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_CHECK,
  LOAD_USER,
  LOAD_USER_SUCCESS,
  LOAD_USER_ERROR,
  LOAD_USER_CANCELLED,
  FETCH_START,
  FETCH_END,
  FETCH_ERROR,
  FETCH_CANCEL,
  USER_UPLOAD,
  USER_UPLOAD_SUCCESS,
  USER_UPLOAD_ERROR,
  USER_FORGOT_PASSWORD,
  USER_RESET_PASSWORD,
} from '../constants';

export const fetchStart = () => ({ type: FETCH_START });
export const fetchEnd = () => ({ type: FETCH_END });
export const fetchError = error => ({ type: FETCH_ERROR, error });
export const fetchCancel = () => ({ type: FETCH_CANCEL });

export function userLogin(payload, pathName) {
  return {
    type: USER_LOGIN,
    payload,
    meta: { auth: true, pathName },
  };
}

export function userLoginError(error) {
  return {
    type: USER_LOGIN_ERROR,
    error,
  };
}

export function userLoginSuccess(payload) {
  return {
    type: USER_LOGIN_SUCCESS,
    payload,
  };
}

export function userLogout(payload) {
  return {
    type: USER_LOGOUT,
    payload,
    meta: { auth: true },
  };
}

export function userCheck(payload, pathName, routeParams) {
  return {
    type: USER_CHECK,
    payload: {
      ...payload,
      routeParams,
    },
    meta: { auth: true, pathName },
  };
}

export function userUpload(payload) {
  return {
    type: USER_UPLOAD,
    payload,
  };
}

export function userUploadError(error) {
  return {
    type: USER_UPLOAD_ERROR,
    error,
  };
}

export function userUploadSuccess(payload) {
  return {
    type: USER_UPLOAD_SUCCESS,
    payload,
  };
}

export function userForgotPassword(payload) {
  return {
    type: USER_FORGOT_PASSWORD,
    payload,
  };
}

export function userResetPassword(payload) {
  return {
    type: USER_RESET_PASSWORD,
    payload,
  };
}

export const loadUser = () => {
  return { type: LOAD_USER };
};
export const loadUserError = error => ({ type: LOAD_USER_ERROR, error });
export const loadUserSuccess = payload => ({ type: LOAD_USER_SUCCESS, payload });
export const loadUserCancelled = () => ({ type: LOAD_USER_CANCELLED });
