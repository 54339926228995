import { createContext } from 'react';

export const authContext = createContext({
  authenticated: false, // to check if authenticated or not
  user: {}, // store all the user details
  accessToken: '', // accessToken of user for Auth0
  permissions: {},
});

export const AuthProvider = authContext.Provider;
export const AuthConsumer = authContext.Consumer;
