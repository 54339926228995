import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter, Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import routes from 'containers/App/routes';
import { Navbar } from 'reactstrap';
import { toggleSidebar } from 'containers/App/actions/theme';

import loadable from '@loadable/component';
import {
  makeSelectLayout,
  makeSelectSidebar,
  makeSelectUserRole,
  makeSelectLocation,
  makeSelectUser,
  makeSelectDataStatus,
} from 'containers/App/selectors';

import { Collapse, Button } from 'reactstrap';
import styled from 'styled-components';
import MasqueradeContainer from 'containers/Masquerade/index';
import MasqueradeLogout from 'containers/Masquerade/out';
import AudiIcons from '../AudiIcons/index';
import { userLogout } from 'containers/App/actions';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { makeSelectMaquerade } from 'containers/Masquerade/selectors';
import { SERVICE_MANAGER, ROLES, SM } from 'containers/App/constants';
import { push } from 'connected-react-router';

const Sidebar = ({ sidebar, match, masqueradeStt, user, userLogout, toggleSidebar, dataStatus, userRole }) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [availRoutes, setAvailRoutes] = useState({});
  const [isMasquerade, setIsMasquerade] = useState(false);

  const handleLogout = () => {
    push('/login');
    userLogout();
  };

  useEffect(() => {
    if (masqueradeStt) {
      setIsMasquerade(masqueradeStt.isMasquerade);
    }
  }, [masqueradeStt]);

  useEffect(() => {
    if (user && user.user) {
      const _availRoutes = [];
      routes.forEach(item => {
        if (!item.accessible || item.accessible.includes(user.user.role_id)) {
          if (item.menu) {
            _availRoutes.push(item);
          }
        }

        if (item.children) {
          item.children.forEach(child => {
            if (child.menu && child.accessible && child.accessible.includes(user.user.role_id)) {
              _availRoutes.push({ ...child });
            }
          });
        }
      });
      var groupBy = (xs, key) => {
        return xs.reduce((rv, x) => {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
      setAvailRoutes(groupBy(_availRoutes, 'id'));
    }
  }, [match, user]);

  useEffect(() => {
    if (match && match.path) {
      const _activeMenu = [];
      routes.forEach(item => {
        if (item.path === match.path) {
          _activeMenu.push(item);
          return;
        }

        if (item.children) {
          const findPath = item.children.filter(child => child.path === match.path);
          if (findPath.length > 0) {
            _activeMenu.push(item);
            return;
          }
        }

        return;
      });
      if (_activeMenu.length > 0) {
        setActiveMenu(_activeMenu[0].id);
      }
    }
  }, [match]);

  const toggle = key => e => {
    if (activeMenu === key) {
      setActiveMenu(null);
    } else {
      setActiveMenu(key);
    }
  };

  const renderText = text => {
    if (text === 'Unsatisfied' && dataStatus && dataStatus.data && dataStatus.data.feedback) {
      return `${text} (${dataStatus.data.feedback.unsatisfied})`;
    } else {
      return text;
    }
  };

  const renderSubRoutes = (items, subItem) => {
    if (subItem.portable) {
      const Portable = loadable(() => import(/* webpackPrefetch: false */ `containers/${subItem.portable.component}`));
      return <Portable key={subItem.path} data={subItem} />;
    }
    if (typeof items === 'object') {
      const render = [];
      Object.keys(items).forEach(el => {
        render.push(
          <div key={el}>
            <NavLinkStyle to={subItem.path.replace(':id', el)} className="sidebar-link py-2">
              {renderText(items[el])}
            </NavLinkStyle>
          </div>,
        );
      });
      return render;
    } else {
      return (
        <div key={subItem.path}>
          <NavLinkStyle to={subItem.path} className="sidebar-link py-2">
            {items}
          </NavLinkStyle>
        </div>
      );
    }
  };

  const renderAvailableRoutes = user => {
    if (!user) return;
    return Object.keys(availRoutes).map(key => {
      return (
        key && (
          <div key={key}>
            <RootMenu
              color="link"
              onClick={toggle(key)}
              className={`${activeMenu === key ? 'open-menu' : 'close-menu'} ${
                user.role_id !== SERVICE_MANAGER ? 'not-collapse' : ''
              }`}
            >
              {key}
            </RootMenu>
            {user.role_id === SERVICE_MANAGER ? (
              <Collapse isOpen={activeMenu === key}>
                <div className="pl-0">{availRoutes[key].map(subItem => renderSubRoutes(subItem.name, subItem))}</div>
              </Collapse>
            ) : (
              <div className="pl-0">
                {availRoutes[key].map(subItem => renderSubRoutes(subItem.name, subItem, availRoutes[key]))}
              </div>
            )}
          </div>
        )
      );
    });
  };

  const height = window.innerHeight;

  return (
    <Nav
      className={
        'sidebar' +
        (!sidebar.isOpen ? ' side-close' : ' side-open') +
        (sidebar.isSticky ? ' sidebar-sticky' : '') +
        (isMasquerade ? ' masquerade' : '')
      }
    >
      {isMasquerade && <MasqueradeLogout />}
      <NavbarStyled light expand>
        <div className="sidebar-toggle d-flex mr-2" onClick={() => toggleSidebar()}>
          {!sidebar.isOpen ? <AudiIcons icon="off-canvas-small" /> : <AudiIcons icon="cancel-small" />}
        </div>
        <MasqueradeContainer user={user ? user.user : {}} />
        {userRole && userRole.id !== SM && (
          <div className="search">
            <Link to="/search/appointments" className="text-white">
              <AudiIcons icon="search-small" />
            </Link>
          </div>
        )}
      </NavbarStyled>
      <div className="sidebar-content">
        <a className="sidebar-brand" href={`${process.env.PUBLIC_URL}`}>
          <AudiIcons icon="logo-menu-white" />
        </a>

        <LinkStyle to="/" className="border-0">
          <RootMenu className="border-0 d-flex overview">Overview</RootMenu>
        </LinkStyle>
        {userRole && userRole.id === SM && (
          <LinkStyle to="/reports" className="border-0">
            <RootMenu className="border-0 d-flex overview">Report</RootMenu>
          </LinkStyle>
        )}
        <PerfectScrollbar className="sidebar-nav" style={{ height: `${height - 400}px` }}>
          <div>{user && renderAvailableRoutes(user.user)}</div>
        </PerfectScrollbar>
        <div>
          {user && user.user && (
            <RootMenu className="border-0 profile-section">
              {userRole && userRole.id !== SM && (
                <div className="px-3 py-4">
                  <Link to="/activity-log">Activity Log</Link>
                </div>
              )}
              <div className="d-flex">
                <Avatar to="/profiles">
                  {user.user.img_url ? (
                    <img
                      src={user.user.img_url}
                      className="avatar img-fluid rounded-circle mr-1 align-self-center"
                      alt="Avatar"
                    />
                  ) : (
                    <AudiIcons icon="icons-menu-cms-user-no-profile-image" />
                  )}
                  <Span>
                    <div className="text-white">{user.user.first_name + ' ' + user.user.last_name}</div>
                    <div>{ROLES[user.user.role_id]}</div>
                  </Span>
                </Avatar>
                {!isMasquerade && (
                  <Button color="transparent" onClick={() => handleLogout()}>
                    <AudiIcons icon="icon-editcreate-calendar" className="hidden" />
                    <AudiIcons icon="icons-menu-logout" />
                  </Button>
                )}
              </div>
            </RootMenu>
          )}
        </div>
      </div>
    </Nav>
  );
};
const LinkStyle = styled(Link)`
  display: block;
  div {
    color: #fff;
    font-family: AudiTypeScreen, Verdana, Geneva, sans-serif;
    font-size: 15px;
    padding: 15px 20px;
  }
  &:hover {
    text-decoration: none;
  }
`;
const Avatar = styled(Link)`
  width: 70%;
  display: flex;
`;
const Span = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  color: rgba(255, 255, 255, 0.55);
  align-self: center;
`;
const Nav = styled.nav`
  &.side-open {
    .navbar {
      display: flex;
      justify-content: space-between;
      width: 220px;
      left: 10px;
    }
    &.masquerade {
      .masquerade-div {
        > span {
          margin-left: 0;
        }
      }
    }
  }
  &.side-close {
    .navbar {
      div:not(:first-child) {
        display: none;
      }
    }
    .search {
      display: none;
    }
    .masquerade-div {
      width: 30px;
    }
  }
  &.masquerade {
    > nav {
      top: 50px;
    }
    .masquerade-div {
      position: fixed;
      background-color: #ac2635;
      width: 256px;
      > span {
        margin-left: 195px;
      }
    }
  }
`;
const NavbarStyled = styled(Navbar)`
  position: fixed;
  top: 10px;
  left: -3px;
  z-index: 1;
  color: #fff;
  box-shadow: none;
`;
const NavLinkStyle = styled(NavLink)`
  &.active {
    background: #4d4d4d;
    font-family: AudiTypeScreenBold, Verdana, Geneva, sans-serif !important;
    font-weight: 500 !important;
  }
`;
const RootMenu = styled.div`
  font-size: 13px;
  color: #7f7f7f;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: 25px;
  border-bottom: 1px solid #4d4d4d;
  position: relative;
  font-family: AudiTypeExtended, Verdana, Geneva, sans-serif;
  cursor: pointer;
  &.not-collapse {
    border-bottom: none;
  }
  &.profile-section {
    position: absolute;
    bottom: 75px;
    width: 100%;
  }
  &.overview {
    margin-top: -20px;
    margin-bottom: 10px;
    margin-left: 0;
    &:hover {
      background: #4d4d4d;
      color: #f0f0f0;
    }
    a {
      margin-left: 20px;
      &:hover {
        text-decoration: none;
      }
    }
  }
  a {
    color: #fff;
  }
  &.open-menu:not(.not-collapse):after {
    vertical-align: 0.255em;
    content: '';
    border: solid !important;
    border-width: 0 2px 2px 0 !important;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    position: absolute;
    top: 18px;
    right: 30px;
  }
  &.close-menu:not(.not-collapse):after {
    vertical-align: 0.255em;
    content: '';
    border: solid !important;
    border-width: 0 2px 2px 0 !important;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    position: absolute;
    top: 18px;
    right: 30px;
  }
`;

const mapStateToProps = createStructuredSelector({
  layout: makeSelectLayout(),
  sidebar: makeSelectSidebar(),
  userRole: makeSelectUserRole(),
  location: makeSelectLocation(),
  masqueradeStt: makeSelectMaquerade(),
  user: makeSelectUser(),
  dataStatus: makeSelectDataStatus(),
});

const mapDispatchToProps = { userLogout, toggleSidebar };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
