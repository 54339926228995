import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, Link, NavLink } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import Main from 'components/Commons/Main';
import Content from 'components/Commons/Content';
import { AuthProvider } from 'services/Rbac/authContext';
import { Navbar, Nav, NavItem, Row, Col, Container } from 'reactstrap';
import AudiIcons from 'components/Commons/AudiIcons/index';
import { SERVICE_MANAGER, WORKSHOP, CSA_ROLE, CALL_CENTRE_ROLE, RECEPTIONIST } from 'containers/App/constants';
import { makeSelectUser } from 'containers/App/selectors';

const Template = ({ children, currentUser, match }) => {
  const [authProviderValue, setAuthProviderValue] = useState({});

  const menus = [
    {
      link: '/search/appointments',
      text: 'Appointments',
      can_access: [SERVICE_MANAGER, WORKSHOP, CSA_ROLE, RECEPTIONIST, CALL_CENTRE_ROLE],
    },
    {
      link: '/search/vehicles',
      text: 'Vehicles',
      can_access: [SERVICE_MANAGER, WORKSHOP, CSA_ROLE, RECEPTIONIST, CALL_CENTRE_ROLE],
    },
    {
      link: '/search/customers',
      text: 'Customers',
      can_access: [SERVICE_MANAGER, CSA_ROLE, RECEPTIONIST, CALL_CENTRE_ROLE],
    },
    {
      link: '/search/employees',
      text: 'Employees',
      can_access: [SERVICE_MANAGER],
    },
    {
      link: '/search/accessories',
      text: 'Accessories',
      can_access: [SERVICE_MANAGER, CSA_ROLE, RECEPTIONIST, CALL_CENTRE_ROLE, WORKSHOP],
    },
    {
      link: '/search/promotions',
      text: 'Promotions',
      can_access: [SERVICE_MANAGER, CSA_ROLE, RECEPTIONIST, CALL_CENTRE_ROLE, WORKSHOP],
    },
  ];

  const [count, setCount] = useState(0);

  useEffect(() => {
    if (currentUser && currentUser.user) {
      setAuthProviderValue({
        ...currentUser.user,
      });
      const _count = menus.filter(item => item.can_access.includes(currentUser.user.role_id)).length;
      setCount(_count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <AuthProvider value={authProviderValue}>
      <Div>
        <Link to="/">
          <AudiIcons icon="cancel-small" />{' '}
        </Link>
      </Div>
      <NavbarStyle color="dark" dark expand="md">
        <Container className="m-auto">
          {currentUser && currentUser.user && (
            <Row className="w-100">
              <Col>
                <Nav navbar className={count > 3 ? 'justify-content-between' : ''}>
                  {menus.map(
                    item =>
                      item.can_access.includes(currentUser.user.role_id) && (
                        <NavItemStyle
                          key={item.text}
                          className={`${match.url === item.link ? 'active' : ''} ${count < 3 ? 'mr-4' : ''}`}
                        >
                          <NavLinkStyle to={`${item.link}`} className="nav-link">
                            {item.text}
                          </NavLinkStyle>
                        </NavItemStyle>
                      ),
                  )}
                </Nav>
              </Col>
            </Row>
          )}
        </Container>
      </NavbarStyle>
      <Main>
        <Content>{children}</Content>
      </Main>
    </AuthProvider>
  );
};
const NavbarStyle = styled(Navbar)`
  background-color: #2d2d2d !important;
`;
const Div = styled.div`
  position: absolute;
  z-index: 1000;
  top: 25px;
  left: 35px;
  svg {
    color: #8c8c8c !important;
  }
  &:hover {
    color: #fff;
  }
`;
const NavItemStyle = styled(NavItem)`
  &.active {
    border-bottom: 2px solid #b20d2a;
  }
`;
const NavLinkStyle = styled(NavLink)`
  font-family: AudiTypeExtended, Verdana, Geneva, sans-serif;
  color: #8c8c8c;
  &:hover {
    color: #fff;
    text-decoration: none;
  }
`;

const mapStateToProps = createStructuredSelector({
  currentUser: makeSelectUser(),
});

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, withConnect)(Template);
