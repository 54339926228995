import {
  FETCH,
  FETCH_SUCCESS,
  FETCH_ERROR,
  FETCH_ONE,
  FETCH_ONE_SUCCESS,
  FETCH_ONE_ERROR,
  CREATE,
  CREATE_SUCCESS,
  CREATE_ERROR,
  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_ERROR,
  DELETE,
  DELETE_SUCCESS,
  DELETE_ERROR,
  CLEAR_ACTION_STATUS,
  CLEAR_MODEL_DATA,
} from './actionTypes';

const initialState = {};
const byIdInitialState = {};

const actionStatusInitialState = {};

const crudReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_MODEL_DATA:
    case CLEAR_ACTION_STATUS:
    case FETCH:
    case FETCH_SUCCESS:
    case FETCH_ERROR:
    case FETCH_ONE:
    case FETCH_ONE_SUCCESS:
    case FETCH_ONE_ERROR:
    case CREATE:
    case CREATE_SUCCESS:
    case CREATE_ERROR:
    case UPDATE:
    case UPDATE_SUCCESS:
    case UPDATE_ERROR:
    case DELETE:
    case DELETE_SUCCESS:
    case DELETE_ERROR:
      const model = (action.meta && action.meta.model) || action.payload.model;
      return Object.assign({}, state, {
        [model]: modelReducerImpl(state[model], action, {
          actionStatusReducerImpl,
          byIdReducerImpl,
        }),
      });

    default:
      return state;
  }
};

const modelReducerImpl = (state = initialState, action) => {
  switch (action.type) {
    case FETCH:
      return Object.assign({}, state, {
        pending: true,
        total_count: 0,
      });

    case FETCH_SUCCESS:
    case FETCH_ERROR:
      const { payload, total } = byIdReducerImpl(state.byId, action);
      return Object.assign({}, state, {
        data: payload,
        total_count: total,
        actionStatus: {},
        pending: false,
      });

    case FETCH_ONE:
      return Object.assign({}, state, {
        record: byIdReducerImpl(state.byId, action),
        pending: true,
      });

    case FETCH_ONE_SUCCESS:
    case FETCH_ONE_ERROR:
      return Object.assign({}, state, {
        record: byIdReducerImpl(state.byId, action),
        pending: false,
      });

    case UPDATE:
    case UPDATE_SUCCESS:
    case UPDATE_ERROR:
      return Object.assign({}, state, {
        record: byIdReducerImpl(state.byId, action),
        actionStatus: actionStatusReducerImpl(state.actionStatus, action),
      });

    case CREATE:
    case CREATE_SUCCESS:
    case CREATE_ERROR:
      return Object.assign({}, state, {
        record: byIdReducerImpl(state, action),
        actionStatus: actionStatusReducerImpl(state.actionStatus, action),
      });

    case DELETE:
    case DELETE_SUCCESS:
    case DELETE_ERROR:
      return Object.assign({}, state, {
        // record: byIdReducerImpl(state.byId, action),
        actionStatus: actionStatusReducerImpl(state.actionStatus, action),
      });

    case CLEAR_MODEL_DATA:
      return Object.assign({}, state, {
        data: null,
      });

    default:
      return;
  }
};

const byIdReducerImpl = (state = byIdInitialState, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      const payload = 'data' in action.payload ? action.payload.data : action.payload;
      return { payload: payload, total: action.payload.total_count };
    // {
    //   data: payload, total_count: action.payload.total_count,
    //   actionStatus: {}
    // }
    case FETCH_ONE_SUCCESS:
    case CREATE_SUCCESS:
    case UPDATE_SUCCESS:
      return action.payload;

    case DELETE_SUCCESS:
      return { id: action.meta.id };

    default:
      return state;
  }
};

const actionStatusReducerImpl = (state = actionStatusInitialState, action) => {
  switch (action.type) {
    case UPDATE:
      delete state.delete;
      delete state.create;
      return Object.assign({}, state, {
        update: {
          pending: true,
          id: action.meta.id,
        },
      });

    case CREATE:
      delete state.delete;
      delete state.update;
      return Object.assign({}, state, {
        create: {
          pending: true,
        },
      });

    case CREATE_ERROR:
      return Object.assign({}, state, {
        create: {
          pending: false,
          isSuccess: !action.error,
          error: action.error,
        },
      });

    case UPDATE_ERROR:
      return Object.assign({}, state, {
        update: {
          pending: false,
          isSuccess: !action.error,
          error: action.error,
        },
      });

    case CREATE_SUCCESS:
      return Object.assign({}, state, {
        create: {
          pending: false,
          isSuccess: !action.error,
          payload: action.payload,
        },
      });

    case DELETE:
      delete state.create;
      delete state.update;
      return Object.assign({}, state, {
        delete: {
          pending: true,
          id: action.meta.id,
        },
        create: {},
        update: {},
      });

    case DELETE_SUCCESS:
      return Object.assign({}, state, {
        delete: {
          pending: false,
          id: action.meta.id,
          isSuccess: !action.error,
          payload: action.payload,
        },
      });

    case UPDATE_SUCCESS:
      return Object.assign({}, state, {
        update: {
          pending: false,
          id: action.meta.id,
          isSuccess: !action.error,
          payload: action.payload,
        },
      });

    default:
      return state;
  }
};

export default crudReducer;
