import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectLayout } from 'containers/App/selectors';

const Wrapper = ({ layout, children }) => (
  <div className={'wrapper ' + (layout.isBoxed ? 'wrapper-boxed' : '')}>{children}</div>
);

const mapStateToProps = createStructuredSelector({
  layout: makeSelectLayout(),
});

export default connect(mapStateToProps)(Wrapper);
