import hoistNonReactStatics from 'hoist-non-react-statics';
import { authContext } from 'services/Rbac/authContext';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

export default ({ perform }) => WrappedComponent => {
  const PermissionInjector = props => {
    const { user, permissions } = useContext(authContext);
    const [result, setResult] = useState(null);
    const [rules, setRules] = useState(null);

    useEffect(() => {
      if (permissions) {
        let newRules = {
          static: [],
        };
        permissions.forEach(item => {
          if (item[2] === '*') {
            newRules.static.push(item[1] + ':list');
            newRules.static.push(item[1] + ':create');
            newRules.static.push(item[1] + ':edit');
            newRules.static.push(item[1] + ':delete');
            newRules.static.push(item[1] + ':view');
          } else {
            newRules.static.push(item[1] + ':' + item[2]);
          }
        });
        setRules({
          ...rules,
          [user.role.id]: newRules,
        });
      }

      const check = (rules, role, action) => {
        const my_permissions = rules[role];
        const staticPermissions = my_permissions.static;
        if (!permissions) return false;
        if (staticPermissions && staticPermissions.includes(action)) return true;
        return false;
      };

      if (rules) {
        const canAccess = check(rules, user.role_id, perform);
        setResult(canAccess);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return result !== null && !result ? <Redirect to="/notfound" /> : <WrappedComponent {...props} />;
  };

  return hoistNonReactStatics(PermissionInjector, WrappedComponent);
};
