import { createSelector } from 'reselect';

const initialState = {
  data: [],
  record: {},
  total_count: 0,
};

const selectData = state => state.crud || initialState;

export const makeSelectStaffCollection = () => createSelector(selectData, state => state.staffs);

export const makeSelectStaffRecord = () =>
  createSelector(selectData, state => {
    return state.staffs && state.staffs.record ? state.staffs.record : {};
  });

export const makeSelectStaffWithMagicNo = () => createSelector(selectData, state => state.staffsWithMagicNo);
export const makeSelectUserRelationCollection = () => createSelector(selectData, state => state.user_relation);
