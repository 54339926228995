import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import AudiIcons from 'components/Commons/AudiIcons/index';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { RadioGroup, Radio, Form, FormGroup } from 'components/AvailityForm/index';
import { masquerade, userMasqueradeCheck } from './actions';
import { fetchCollection } from 'services/Crud/actionCreator';

import sagaMaquerade from './sagas';
import reducerMaquerade from './reducers';

import saga from 'services/Crud/sagas';
import reducer from 'services/Crud/reducers';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import withPermissions from 'utils/permissions';
import { CSA_ROLE, CALL_CENTRE_ROLE, RECEPTIONIST, WORKSHOP } from 'containers/App/constants';
import { makeSelectStaffCollection } from 'containers/Employees/selectors';
import { Label } from 'reactstrap';
import styled from 'styled-components';
import TableRemoteAll from 'components/Tables/TableRemoteAll';
import Loader from 'components/Commons/Loader';
import { SERVICE_MANAGER } from 'containers/App/constants';
import { makeSelectMaquerade } from 'containers/Masquerade/selectors';
import { loadUser } from 'containers/App/actions';
import { hideSidebar } from 'containers/App/actions/theme';

const MasqueradeContainer = ({
  fetchCollection,
  staffCollection,
  masquerade,
  userMasqueradeCheck,
  user,
  masqueradeStt,
  loadUser,
  hideSidebar,
}) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [initState, setInitState] = useState({
    role_id: 0,
    user: 0,
  });
  const [realUser, setRealUser] = useState({});
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [disabled, setDisabled] = useState(true);
  // const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (user) {
      setRealUser(user);
    }
  }, [user]);

  useEffect(() => {
    if (masqueradeStt && (masqueradeStt.isSuccess || masqueradeStt.isOut)) {
      loadUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masqueradeStt]);

  useEffect(() => {
    userMasqueradeCheck();
  }, [userMasqueradeCheck]);

  useEffect(() => {
    if (initState.role_id && modal) {
      setLoading(true);
      fetchCollection('staffs', 'staffs', {
        filter: { 'users.role_id': initState.role_id, 'users.active': true },
      });
    }
  }, [initState.role_id, fetchCollection, modal]);

  useEffect(() => {
    if (staffCollection && staffCollection.data) {
      setList(staffCollection.data);
      setLoading(false);
      setTotal(staffCollection.total_count);
    }
  }, [staffCollection]);

  const handleOnChange = (value, field) => {
    setInitState({
      ...initState,
      [field]: value,
    });
    setDisabled(true);
  };

  const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    style: { backgroundColor: '#e6e3e1' },
    onSelect: row => {
      handleOnChange(row, 'user');
      setDisabled(false);
    },
  };

  useEffect(() => {
    const bodyClassname = document.body.className;
    if (!modal && bodyClassname.includes('modal-open')) {
      document.body.className = bodyClassname.replace('modal-open', '');
    }
    if (modal) {
      hideSidebar();
    }
  }, [modal, hideSidebar]);

  const handleSubmit = () => {
    setModal(false);
    handleMasquerade();
  };

  const handleMasquerade = () => {
    masquerade({
      user_id: initState.user.user_id,
    });
  };

  return (
    <div>
      {realUser.role_id === SERVICE_MANAGER && (
        <Button color="link" onClick={toggle}>
          <AudiIcons icon="icons-menu-masquarade" />
        </Button>
      )}
      <Modal isOpen={modal} toggle={toggle} centered size="lg">
        <ModalHeader toggle={toggle}>Masquerade</ModalHeader>
        <ModalBody className="p-0">
          <Form initialValues={{}}>
            <FormGroup className="px-3">
              <RadioGroup
                name="role"
                label="Would you like to masquerade as"
                onChange={e => handleOnChange(e, 'role_id')}
                className="justify-content-between"
              >
                <Radio value={CSA_ROLE}>CSA</Radio>
                <Radio value={CALL_CENTRE_ROLE}>Call Centre</Radio>
                <Radio value={RECEPTIONIST}>Reception</Radio>
                <Radio value={WORKSHOP}>Workshop</Radio>
              </RadioGroup>
            </FormGroup>

            {initState.role_id !== 0 &&
              (!loading ? (
                <Div className="p-3">
                  <Label>Select a user</Label>
                  <TableRemoteAll
                    columns={[
                      {
                        dataField: 'first_name',
                        formatter: (cell, row) => (
                          <div>
                            <AudiIcons icon="icon-editcreate-employee" className="mr-4" />
                            {cell + ' ' + row.last_name}
                          </div>
                        ),
                        text: '',
                      },
                    ]}
                    data={list ? list : []}
                    onTableChange={() => {}}
                    selectRow={selectRow}
                    noPadding={true}
                    totalSize={total}
                  />
                </Div>
              ) : (
                <Loader />
              ))}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" className="mr-2" onClick={() => toggle()}>
            Cancel
          </Button>
          <ButtonStyle color="red" onClick={() => handleSubmit()} disabled={disabled}>
            Start Session
          </ButtonStyle>
        </ModalFooter>
      </Modal>
    </div>
  );
};
const ButtonStyle = styled(Button)`
  &.disabled {
    opacity: 0.7;
  }
`;
const Div = styled.div`
  background-color: #f5f5f5;
  > div {
    margin-top: 0 !important;
  }
`;
const withReducer = injectReducer({ key: 'crud', reducer });
const withSaga = injectSaga({ key: 'crud', saga });

const withReducerMaquerade = injectReducer({ key: 'masquerade', reducer: reducerMaquerade });
const withSagaMaquerade = injectSaga({ key: 'masquerade', saga: sagaMaquerade });

const mapStateToProps = createStructuredSelector({
  staffCollection: makeSelectStaffCollection(),
  masqueradeStt: makeSelectMaquerade(),
});

const mapDispatchToProps = {
  masquerade,
  fetchCollection,
  userMasqueradeCheck,
  loadUser,
  hideSidebar,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withPerm = withPermissions({
  perform: 'staff:delete',
});

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect,
  withPerm,
  withReducerMaquerade,
  withSagaMaquerade,
)(MasqueradeContainer);
