import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import AudiIcons from 'components/Commons/AudiIcons';
import styled from 'styled-components';
import setMinutes from 'date-fns/setMinutes';
import setHours from 'date-fns/setHours';

const CustomDatePicker = ({
  selected,
  onChange,
  field,
  minDate,
  maxDate,
  minTime,
  maxTime,
  showTimeSelect,
  showTimeSelectOnly,
  dateFormat,
  required,
  className,
  disabled,
  placeholderText,
  groupClassname,
  showFullMonthYearPicker,
  showMonthYearPicker,
}) => {
  const onChangeInput = date => {
    if (Object.prototype.toString.call(date) !== '[object Date]') return;
    if (!showTimeSelectOnly) {
      onChange(moment(date).format('Y-MM-DD'), field);
    } else {
      onChange(moment(date).format('HH:mm'), field);
    }
  };

  return (
    <div
      className={`position-relative ${showTimeSelectOnly ? 'time' : 'date'} ${groupClassname ? groupClassname : ''}`}
    >
      <DatePicker
        disabled={disabled}
        required={required}
        className={className}
        selected={selected ? new Date(selected) : ''}
        dateFormat={dateFormat.replace('Y', 'y')}
        onChange={date => onChangeInput(date)}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        minTime={minTime ? setHours(setMinutes(new Date(), minTime[1]), minTime[0]) : null}
        maxTime={maxTime ? setHours(setMinutes(new Date(), maxTime[1]), maxTime[0]) : null}
        minDate={minDate ? new Date(minDate) : ''}
        maxDate={maxDate ? new Date(maxDate) : ''}
        placeholderText={placeholderText}
        showMonthYearPicker={showMonthYearPicker}
        showFullMonthYearPicker={showFullMonthYearPicker}
      />
      {!showTimeSelectOnly && <AudiIconsStyle icon="icon-input-calendar" />}
    </div>
  );
};

const AudiIconsStyle = styled(AudiIcons)`
  position: absolute;
  right: 11px;
  top: 5px;
`;

export default CustomDatePicker;
