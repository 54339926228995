const masqueradeService = `constainers/MASQUERADE`;

export const MASQUERADE = `${masqueradeService}/MASQUERADE`;
export const MASQUERADE_SUCCESS = `${masqueradeService}/MASQUERADE_SUCCESS`;
export const MASQUERADE_ERROR = `${masqueradeService}/MASQUERADE_ERROR`;

export const CHECK_MASQUERADE = `${masqueradeService}/CHECK_MASQUERADE`;
export const CHECK_MASQUERADE_SUCCESS = `${masqueradeService}/CHECK_MASQUERADE_SUCCESS`;
export const CHECK_MASQUERADE_ERROR = `${masqueradeService}/CHECK_MASQUERADE_ERROR`;

export const MASQUERADE_OUT = `${masqueradeService}/MASQUERADE_OUT`;
export const MASQUERADE_OUT_SUCCESS = `${masqueradeService}/MASQUERADE_OUT_SUCCESS`;
export const MASQUERADE_OUT_ERROR = `${masqueradeService}/MASQUERADE_OUT_ERROR`;
