import produce from 'immer';
import * as types from '../constants';

const initialState = {
  isBoxed: false,
};

const myReducer = (state = initialState, actions) =>
  produce(state, draft => {
    switch (actions.type) {
      case types.LAYOUT_BOXED_TOGGLE:
        draft.isBoxed = !state.isBoxed;
        break;
      case types.LAYOUT_BOXED_ENABLE:
        draft.isBoxed = true;
        break;
      case types.SIDEBAR_STICKY_ENABLE:
      case types.SIDEBAR_STICKY_TOGGLE:
      case types.LAYOUT_BOXED_DISABLE:
        draft.isBoxed = false;
        break;
      default: // for eslint
    }
  });

export default myReducer;
