import React from 'react';
import { withRouter } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import PropTypes from 'prop-types';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';

import styled from 'styled-components';
const TableRemoteAll = ({
  columns,
  data,
  page,
  onTableChange,
  totalSize,
  object,
  selectRow,
  ignoreEventOnId,
  rowClasses,
  className,
  noPadding,
  clickableRow = true,
}) => {
  const rowEvents = {
    onClick: (e, row) => {
      let ignoreOnClick = 0;
      if (typeof e.target.className === 'string') {
        if (e.target.className.includes('ignoreOnClick')) {
          ignoreOnClick += 1;
        }
      } else if (typeof e.target.className === 'object') {
        const wrapper = e.target.parentElement.parentElement;
        if (typeof wrapper.className === 'string' && wrapper.className.includes('ignoreOnClick')) {
          ignoreOnClick += 1;
        }
      }
      if (ignoreEventOnId && (ignoreEventOnId.includes(e.target.id) || ignoreEventOnId.includes(e.target.name))) {
        ignoreOnClick += 1;
      }

      if (ignoreOnClick > 0) {
        e.preventDefault();
      } else if (object.link) {
        object.link(row);
      }
    },
  };

  const options = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    totalSize: 1544,
    sizePerPage: 50,
  };

  return (
    <PaginationProvider pagination={paginationFactory(options)} columns={columns}>
      {({ paginationProps, paginationTableProps }) => {
        paginationProps.dataSize = totalSize;
        paginationProps.page = page;
        return (
          <ToolkitProvider keyField="id" columns={columns} data={data} search>
            {toolkitprops => (
              <>
                <Div className="mt-4">
                  {object && object.name && <Span className="px-4">{`${object.name}`}</Span>}
                  <div className={noPadding ? '' : 'p-4'}>
                    <BootstrapTable
                      classes={className ? className : ''}
                      bordered={false}
                      {...toolkitprops.baseProps}
                      {...paginationTableProps}
                      remote={{ filter: true, sort: true, pagination: true }}
                      onTableChange={onTableChange}
                      wrapperClasses={rowEvents && clickableRow ? 'clickable-row' : ''}
                      noDataIndication="No results"
                      rowEvents={!selectRow && clickableRow ? rowEvents : null}
                      selectRow={selectRow}
                      rowClasses={rowClasses}
                    />
                  </div>
                </Div>
                {page && (
                  <div className="d-flex justify-content-center">
                    <PaginationListStandalone {...paginationProps} className="d-inline mr-auto" />
                  </div>
                )}
              </>
            )}
          </ToolkitProvider>
        );
      }}
    </PaginationProvider>
  );
};

const Div = styled.div`
  .overdue {
    background-color: #ebbac4;
  }
  .duesoon {
    background-color: #ffbd1c;
  }
  .even {
    background: #f6f6f6;
  }
`;
const Span = styled.span`
  background-color: #e5e5e5;
  padding: 3px 20px;
`;

TableRemoteAll.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  page: PropTypes.number,
  sizePerPage: PropTypes.number,
  // onTableChange: PropTypes.oneOfType([PropTypes.function, undefined]),
  totalSize: PropTypes.number.isRequired,
  object: PropTypes.object,
  ignoreEventOnId: PropTypes.array,
};

export default withRouter(TableRemoteAll);

export const sortCaret = (column, sort) => {
  const IStyled = styled.i`
    border: solid #495057;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 10px;
    &.unsort {
      border: solid #e5e5e5;
      border-width: 0 2px 2px 0;
    }
    &.up {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }
    &.down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  `;
  if (!sort || sort.s !== column.dataField) return <IStyled className="arrow up unsort"></IStyled>;
  else if (sort.o === 'asc') return <IStyled className="arrow up"></IStyled>;
  else if (sort.o === 'desc') return <IStyled className="arrow down"></IStyled>;
  return null;
};
