import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import ReduxToastr from 'react-redux-toastr';
import { connect } from 'react-redux';

import Async from 'components/Commons/Async';
import Authenticated from 'components/Commons/Authenticated';
import ScrollToTop from 'components/Commons/ScrollToTop';

import AuthLayout from 'themes/Auth';

import { routesByRole } from './routes';
import { createStructuredSelector } from 'reselect';
import { makeSelectUserRole, makeSelectDataStatus } from './selectors';
import { fetchCollection } from 'services/Crud/actionCreator';

const App = ({ userRole, fetchCollection, dataStatus }) => {
  const availabelRoutes = routesByRole(userRole);
  const [role, setRole] = useState({});
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (!loading && location.pathname.includes('appointments')) {
      setLoading(true);
      fetchCollection('dataState', 'overview/startup', {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (userRole && userRole.id && role !== userRole.id && !loading) {
      setRole(userRole.id);
      fetchCollection('dataState', 'overview/startup', {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole]);

  useEffect(() => {
    if (dataStatus && dataStatus.data) {
      setLoading(false);
    }
  }, [dataStatus]);

  const routeRenderer = (Layout = AuthLayout, pageComponent, noAuth) => props => {
    if (noAuth) {
      return (
        <Layout>
          <Async page={pageComponent} />
        </Layout>
      );
    }
    return (
      <Authenticated {...props}>
        <Layout>
          <Async page={pageComponent} role={userRole} />
        </Layout>
      </Authenticated>
    );
  };

  const routeListRedenrer = availabelRoutes => {
    let render = [];
    availabelRoutes.forEach(({ noAuth, children, path, pageComponent, layout, isExact = false }, index) => {
      render.push(
        <Route key={`${pageComponent}-root`} path={path} exact render={routeRenderer(layout, pageComponent, noAuth)} />,
      );
      if (children) {
        children.forEach(({ path, pageComponent, isExact = false }, index) => {
          render.push(
            <Route
              key={`${pageComponent}-${index}-child`}
              path={path}
              exact
              render={routeRenderer(layout, pageComponent, noAuth, 'children')}
            />,
          );
        });
      }
      return;
    });
    return render;
  };

  return (
    <ScrollToTop>
      <Helmet titleTemplate="%s - AUDI CMS" defaultTitle="AUDI CMS">
        <meta name="description" content="AUDI CMS" />
      </Helmet>
      <Switch>{routeListRedenrer(availabelRoutes)}</Switch>
      <ReduxToastr
        timeOut={10000}
        newestOnTop={true}
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </ScrollToTop>
  );
};

const mapStateToProps = createStructuredSelector({
  userRole: makeSelectUserRole(),
  dataStatus: makeSelectDataStatus(),
});

const mapDispatchToProps = {
  fetchCollection,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
