import { takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { FETCH_ERROR } from 'containers/App/constants';

function handler({ error }) {
  if (error) {
    let msg = error.message ? error.message : String(error);
    toastr.error(msg);
  }
}

export default function* fetchSaga() {
  yield takeEvery(FETCH_ERROR, handler);
}
