import * as types from '../constants';

import { toggleTheme } from '../themeService';

export const enableClassicTheme = () => enableTheme('classic');
export const enableCorporateTheme = () => enableTheme('corporate');
export const enableModernTheme = () => enableTheme('modern');

function enableTheme(name) {
  toggleTheme(name);

  return {
    type: types.THEME_TOGGLE,
    payload: name,
  };
}

// layout actions
export const toggleBoxedLayout = () => ({ type: types.LAYOUT_BOXED_TOGGLE });
export const enableBoxedLayout = () => ({ type: types.LAYOUT_BOXED_ENABLE });
export const disableBoxedLayout = () => ({ type: types.LAYOUT_BOXED_DISABLE });

// sidebar actions
export const toggleSidebar = () => ({ type: types.SIDEBAR_VISIBILITY_TOGGLE });
export const showSidebar = () => ({ type: types.SIDEBAR_VISIBILITY_SHOW });
export const hideSidebar = () => ({ type: types.SIDEBAR_VISIBILITY_HIDE });
export const toggleStickySidebar = () => ({ type: types.SIDEBAR_STICKY_TOGGLE });
export const enableStickySidebar = () => ({ type: types.SIDEBAR_STICKY_ENABLE });
export const disableStickySidebar = () => ({ type: types.SIDEBAR_STICKY_DISABLE });
