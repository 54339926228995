/**
 * The global state selectors
 */

import { createSelector } from 'reselect';

export const selectGlobal = state => state.global;

export const selectRouter = state => state.router;

export const selectApp = state => state.app;

export const makeSelectLayout = () => createSelector(selectGlobal, globalState => globalState.layout);

export const makeSelectSidebar = () => createSelector(selectGlobal, globalState => globalState.sidebar);

export const makeSelectTheme = () => createSelector(selectGlobal, globalState => globalState.theme);

export const makeSelectCurrentTheme = () => createSelector(makeSelectTheme(), theme => theme.currentTheme);

export const makeSelectLoading = () => createSelector(selectGlobal, globalState => globalState.loading);

export const makeSelectToken = () => createSelector(selectGlobal, globalState => globalState.auth.token);

export const makeSelectUser = () =>
  createSelector(selectGlobal, globalState => {
    return globalState.auth.user;
  });

export const makeSelectUserRole = () =>
  createSelector(makeSelectUser(), userState => {
    if (!userState) return { id: null };
    if (userState.role_id && userState.role) {
      return {
        id: userState.role_id,
        name: userState.role,
      };
    }
    if (userState.user) {
      return userState.user.role;
    }
  });

export const makeSelectUserLocation = () =>
  createSelector(makeSelectUser(), userState => {
    return userState && userState.user && userState.user.staff ? userState.user.staff.location_id : null;
  });

export const makeSelectUserSettings = () =>
  createSelector(makeSelectUser(), userState => (userState ? userState.settings : undefined));

export const makeSelectLocation = () => createSelector(selectRouter, routerState => routerState.location);

export const makeSelectNextPathname = () =>
  createSelector(selectRouter, routerState => routerState.location.state && routerState.location.state.nextPathname);

const selectFile = state => (state && state['app'] && state['app'].uploadedFile) || false;
export const makeSelectUploadedFile = () => createSelector(selectFile, state => state);

const selectData = state => {
  return state.crud || {};
};

export const makeSelectDataStatus = () =>
  createSelector(selectData, state => {
    return state.dataState;
  });
