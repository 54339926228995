import NoAuthLayout from 'themes/NoAuth';
import SearchLayout from 'themes/SearchTheme';
import { RECEPTIONIST, CALL_CENTRE_ROLE, SERVICE_MANAGER, SM, CSA_ROLE } from './constants';
import TvScreen from 'themes/TvScreen';
import smRouter from 'containers/SmReport/routes';

const routes = [
  {
    path: '/login',
    noAuth: true,
    layout: NoAuthLayout,
    pageComponent: 'Login',
  },
  {
    path: '/tv-screen/:location_id',
    noAuth: true,
    layout: TvScreen,
    pageComponent: 'TvScreen',
  },
  {
    path: '/report',
    accessible: [SM],
    id: '',
    menu: true,
    name: 'Report',
    pageComponent: 'Report',
  },
  {
    path: '/tv-welcome/:location_id',
    noAuth: true,
    layout: TvScreen,
    pageComponent: 'TvScreen/welcome-screen',
  },
  {
    path: '/give-feedback/:id',
    noAuth: true,
    layout: NoAuthLayout,
    pageComponent: 'Feedback/customer-feedback',
  },
  {
    path: '/setup-password/:token',
    noAuth: true,
    layout: NoAuthLayout,
    pageComponent: 'Login/views/update-password',
  },
  {
    path: '/forgot-password',
    noAuth: true,
    layout: NoAuthLayout,
    pageComponent: 'Login/views/forgot-password',
  },
  {
    path: '/reset-password/:token',
    noAuth: true,
    layout: NoAuthLayout,
    pageComponent: 'Login/views/update-password',
  },
  {
    path: '/',
    pageComponent: 'Dashboard',
    pageTitle: 'Dashboard',
    name: 'Dashboard',
  },
  {
    path: '/leave-applications',
    menu: true,
    accessible: [SERVICE_MANAGER],
    name: 'Leave Application',
    id: 'Operations',
    pageComponent: 'Operations/LeaveApplications/views/list',
    children: [
      {
        path: '/leave-applications/:id/edit',
        pageComponent: 'Operations/LeaveApplications/forms',
      },
      {
        path: '/leave-applications/:id/delete',
        pageComponent: 'Operations/LeaveApplications/forms/delete',
      },
      {
        path: '/leave-applications/create',
        pageComponent: 'Operations/LeaveApplications/forms',
      },
    ],
  },
  {
    path: '/shift-management',
    menu: true,
    accessible: [SERVICE_MANAGER],
    name: 'Shift Management',
    id: 'Operations',
    pageComponent: 'Operations/Shifts/views/list',
    children: [
      {
        path: '/shift-management/temporary-shift',
        pageComponent: 'Operations/Shifts/forms/temp-shift',
      },
      {
        path: '/shift-management/:id/edit',
        pageComponent: 'Operations/Shifts/forms',
      },
      {
        path: '/shift-management/create',
        pageComponent: 'Operations/Shifts/forms',
      },
    ],
  },
  {
    path: '/public-holidays',
    menu: true,
    accessible: [SERVICE_MANAGER],
    name: 'Public Holiday',
    id: 'Operations',
    pageComponent: 'Operations/PublicHolidays/views/list',
    children: [
      {
        path: '/public-holidays/:id/edit',
        pageComponent: 'Operations/PublicHolidays/forms',
      },
      {
        path: '/public-holidays/:id/delete',
        pageComponent: 'Operations/PublicHolidays/forms/delete',
      },
      {
        path: '/public-holidays/create',
        pageComponent: 'Operations/PublicHolidays/forms',
      },
    ],
  },
  {
    path: '/slot-blockings',
    menu: true,
    accessible: [SERVICE_MANAGER],
    id: 'Operations',
    name: 'Slot Blocking',
    pageComponent: 'Operations/SlotBlocking/views/list',
  },
  {
    path: '/service-centre-capacities',
    menu: true,
    accessible: [SERVICE_MANAGER],
    id: 'Operations',
    name: 'Service Centre Capacity',
    pageComponent: 'Operations/Capacities/views/list',
    children: [
      {
        path: '/service-centre-capacities/:id/edit',
        pageComponent: 'Operations/Capacities/forms',
      },
    ],
  },
  {
    path: '/exit-interview-mgmt',
    menu: true,
    accessible: [SERVICE_MANAGER],
    id: 'Operations',
    name: 'Exit Interview Mgmt',
    pageComponent: 'Operations/ExitInterviewMgmt/views',
    children: [
      {
        path: '/exit-interview-mgmt/:id/edit',
        pageComponent: 'Operations/ExitInterviewMgmt/forms',
      },
      {
        path: '/exit-interview-mgmt/create',
        pageComponent: 'Operations/ExitInterviewMgmt/forms',
      },
    ],
  },
  {
    path: '/last-login',
    menu: true,
    accessible: [SERVICE_MANAGER],
    id: 'Operations',
    name: 'Last Login Report',
    pageComponent: 'Customers/views/last-login',
  },
  {
    path: '/staffs/create/:rid',
    id: 'User Management',
    pageComponent: 'Employees/forms',
    accessible: [SERVICE_MANAGER],
    children: [
      {
        path: '/staffs/:id',
        menu: true,
        accessible: [SERVICE_MANAGER],
        id: 'User Management',
        name: {
          4: 'Call Centre Agent',
          5: 'Receptionist',
          2: 'CSA',
          3: 'SA',
          6: 'Foreman',
          7: 'Workshop',
          8: 'Technician',
          11: 'Service Manager',
        },
        pageComponent: 'Employees',
      },
      {
        path: '/staffs/:sid/edit',
        pageComponent: 'Employees/forms',
      },
      {
        path: '/staffs/:id/delete',
        pageComponent: 'Employees/forms/delete',
      },
    ],
  },
  {
    path: '/models-variants',
    pageComponent: 'Mapping/Cars',
    menu: true,
    accessible: [SERVICE_MANAGER],
    id: 'Category Management',
    name: 'Models & Variants',
    children: [
      {
        path: '/models-variants/:id/edit',
        pageComponent: 'Mapping/Cars/forms',
      },
      {
        path: '/models-variants/create',
        pageComponent: 'Mapping/Cars/forms',
      },
    ],
  },
  {
    path: '/promotion-categories',
    menu: true,
    accessible: [SERVICE_MANAGER],
    id: 'Category Management',
    name: 'Promotion Categories',
    pageComponent: 'Mapping/PromotionCategories/views/index',
    children: [
      {
        path: '/promotion-categories/:acid/edit',
        pageComponent: 'Mapping/PromotionCategories/forms',
      },
      {
        path: '/promotion-categories/:acid/delete',
        pageComponent: 'Mapping/PromotionCategories/forms/delete',
      },
      {
        path: '/promotion-categories/create',
        pageComponent: 'Mapping/PromotionCategories/forms',
      },
    ],
  },
  {
    path: '/accessory-categories',
    menu: true,
    accessible: [SERVICE_MANAGER],
    id: 'Category Management',
    name: 'Accessory Categories',
    pageComponent: 'Mapping/AccessoryCategories/views/list',
    children: [
      {
        path: '/accessory-categories/:acid/edit',
        pageComponent: 'Mapping/AccessoryCategories/forms',
      },
      {
        path: '/accessory-categories/:acid/delete',
        pageComponent: 'Mapping/AccessoryCategories/forms/delete',
      },
      {
        path: '/accessory-categories/create',
        pageComponent: 'Mapping/AccessoryCategories/forms',
      },
    ],
  },
  {
    path: '/promotions',
    menu: true,
    id: 'Media',
    accessible: [SERVICE_MANAGER, 2, RECEPTIONIST, CALL_CENTRE_ROLE],
    name: 'Promotions',
    pageComponent: 'Media/Promotions/views/list',
    children: [
      {
        path: '/promotions/create',
        name: 'Create Promotions',
        pageComponent: 'Media/Promotions/forms',
        pageTitle: 'Promotions',
      },
      {
        path: '/promotions/:id/edit',
        name: 'Edit Promotions',
        pageComponent: 'Media/Promotions/forms',
        pageTitle: 'Promotions',
      },
    ],
  },
  {
    path: '/accessories',
    id: 'Media',
    accessible: [SERVICE_MANAGER, 2, RECEPTIONIST, CALL_CENTRE_ROLE],
    pageComponent: 'Media/Accessories',
    name: 'Accessories',
    menu: true,
    children: [
      {
        path: '/accessories/create',
        name: 'Create Accessories',
        pageComponent: 'Media/Accessories/forms',
        pageTitle: 'Accessories',
      },
      {
        path: '/accessories/is_discover/edit',
        name: 'Create Accessories',
        pageComponent: 'Media/Accessories/components/discovers/list',
        pageTitle: 'Accessories',
      },
      {
        path: '/accessories/is_discover/add',
        name: 'Create Accessories',
        pageComponent: 'Media/Accessories/components/discovers/form',
        pageTitle: 'Accessories',
      },
      {
        path: '/accessories/:aid/edit',
        accessible: [SERVICE_MANAGER, 2, RECEPTIONIST],
        name: 'Detail',
        pageComponent: 'Media/Accessories/forms',
        pageTitle: 'Accessories',
      },
    ],
  },
  {
    path: '/tsa-images',
    menu: true,
    accessible: [SERVICE_MANAGER],
    id: 'Media',
    name: 'TSA Images',
    pageComponent: 'Media/Tsa/views',
    children: [
      {
        path: '/tsa-images/:id/edit',
        pageComponent: 'Media/Tsa/forms',
      },
    ],
  },
  {
    path: '/customers',
    accessible: [SERVICE_MANAGER, CALL_CENTRE_ROLE],
    pageComponent: 'Customers',
    name: 'App Account Requests',
    id: 'Customers',
    menu: true,
    children: [
      {
        path: '/customers/:cid/edit',
        pageComponent: 'Customers/forms/customer-detail',
      },
      {
        path: '/app-account-request/:cid/edit',
        pageComponent: 'Customers/forms',
      },
      {
        path: '/profile-updates',
        pageComponent: 'Customers/views/profile-updates',
        menu: true,
        accessible: [SERVICE_MANAGER, CALL_CENTRE_ROLE],
        name: 'Profile Updates',
        id: 'Customers',
      },
      {
        path: '/profile-updates/:id/view',
        pageComponent: 'Customers/views/profile-updates-items',
        id: 'Customers',
      },
    ],
  },
  {
    path: '/appointments/:type/:status',
    menu: true,
    accessible: [7],
    id: 'In Workshop',
    pageComponent: 'Appointments/views/list',
    portable: {
      component: 'Appointments/sidebar',
      branch: 'inworkshop',
    },
  },
  {
    path: '/appointments/create',
    pageComponent: 'Appointments/forms',
    accessible: [SERVICE_MANAGER],
    id: 'Appointments',
    children: [
      {
        path: '/appointment/:aid/edit',
        pageComponent: 'Appointments/forms',
      },
      {
        path: '/appointments/:type',
        menu: true,
        portable: {
          component: 'Appointments/sidebar',
          branch: 'appointment',
        },
        accessible: [SERVICE_MANAGER, 7, 2, RECEPTIONIST, CALL_CENTRE_ROLE],
        id: 'Appointments',
        pageComponent: 'Appointments/views/list',
      },
    ],
  },
  {
    path: '/emails',
    accessible: [SERVICE_MANAGER],
    pageComponent: 'Notifications/Email/views',
    name: 'Email',
    id: 'Notifications',
    menu: true,
    children: [
      {
        path: '/emails/:id/edit',
        id: 'Notifications',
        pageComponent: 'Notifications/Email/forms',
      },
    ],
  },
  {
    path: '/notifications/sms',
    name: 'SMS',
    accessible: [SERVICE_MANAGER],
    menu: true,
    id: 'Notifications',
    pageComponent: 'Notifications/Sms/views',
    children: [
      {
        path: '/sms/:id/edit',
        id: 'Notifications',
        pageComponent: 'Notifications/Sms/forms',
      },
    ],
  },
  {
    path: '/push-notifications',
    name: 'Push Notifications',
    accessible: [SERVICE_MANAGER],
    menu: true,
    id: 'Notifications',
    pageComponent: 'Notifications/Push/views',
    children: [
      {
        path: '/push-notifications/create',
        id: 'Notifications',
        pageComponent: 'Notifications/Push/forms',
      },
      {
        path: '/push-notifications/:id/edit',
        id: 'Notifications',
        pageComponent: 'Notifications/Push/forms',
      },
    ],
  },
  {
    path: '/msa',
    pageComponent: 'Notifications/Msa',
    pageTitle: 'MSA',
    name: 'MSA',
    id: 'Notifications',
    menu: true,
    accessible: [SERVICE_MANAGER, CSA_ROLE],
  },
  {
    path: '/feedbacks',
    id: 'Feedbacks',
    pageComponent: 'Feedback/views/list',
    accessible: [SERVICE_MANAGER, RECEPTIONIST],
    children: [
      {
        path: '/feedbacks/:id',
        menu: true,
        accessible: [SERVICE_MANAGER, 2, RECEPTIONIST],
        pageComponent: 'Feedback/views/list',
        id: 'Feedbacks',
        name: {
          unsatisfied: 'Unsatisfied',
          satisfied: 'Satisfied',
        },
      },
    ],
  },
  {
    path: '/vehicles/:id',
    id: 'Vehicle History',
    pageComponent: 'Search/views/vehicle-history',
    accessible: [SERVICE_MANAGER],
  },
  {
    path: '/search/appointments',
    accessible: [SERVICE_MANAGER],
    layout: SearchLayout,
    pageComponent: 'Search/views/appts',
    id: 'Search',
    children: [
      {
        path: '/search/vehicles',
        accessible: [SERVICE_MANAGER],
        layout: SearchLayout,
        pageComponent: 'Search/views/vehicles',
        id: 'Search',
      },
      {
        path: '/search/customers',
        accessible: [SERVICE_MANAGER],
        layout: SearchLayout,
        pageComponent: 'Search/views/customers',
        id: 'Search',
      },
      {
        path: '/search/employees',
        accessible: [SERVICE_MANAGER],
        layout: SearchLayout,
        pageComponent: 'Search/views/employees',
        id: 'Search',
      },
      {
        path: '/search/accessories',
        accessible: [SERVICE_MANAGER],
        layout: SearchLayout,
        pageComponent: 'Search/views/accessories',
        id: 'Search',
      },
      {
        path: '/search/promotions',
        accessible: [SERVICE_MANAGER],
        layout: SearchLayout,
        pageComponent: 'Search/views/promotions',
        id: 'Search',
      },
    ],
  },
  {
    path: '/activity-log',
    pageComponent: 'Logs/views/index',
    pageTitle: 'Profiles',
    name: 'Profiles',
    noMenu: true,
  },
  {
    path: '/profiles',
    pageComponent: 'Profiles/views',
    pageTitle: 'Profiles',
    name: 'Profiles',
    noMenu: true,
  },
  {
    path: '/push-notification-app',
    pageComponent: 'PushNotif',
    pageTitle: 'Push Notification',
    name: 'Push Notification',
    noMenu: true,
  },
  {
    path: '/invoices/:vid/:aid',
    pageComponent: 'Search/views/invoices',
  },
  {
    path: '/jobcard/:vid/:aid',
    pageComponent: 'Search/views/jobcards',
  },
  {
    path: '*',
    noAuth: true,
    layout: NoAuthLayout,
    pageComponent: 'App/views/NotFoundPage',
  },
];

export default routes;

export const routesByRole = (userRole = {}) => {
  let routesByRole;
  if (userRole.id === SM) {
    routesByRole = smRouter;
  } else {
    routesByRole = routes;
  }

  return routesByRole.filter(r => {
    if (!r.role || !userRole) {
      return true;
    } else if (typeof r.role == 'string') {
      return r.role === userRole;
    } else if (Array.isArray(r.role)) {
      for (let i = 0; i < r.role.length; i++) {
        if (r.role[i] === userRole) {
          return true;
        }
      }
    }
    return false;
  });
};
