import React from 'react';

import Main from 'components/Commons/Main';

const Template = ({ children }) => (
  <React.Fragment>
    <Main className="d-flex w-100 overflow-hidden">{children}</Main>
  </React.Fragment>
);

export default Template;
