import produce from 'immer';
import * as types from '../constants';

const initialState = {
  isOpen: true,
  isSticky: true,
};

const myReducer = (state = initialState, actions) =>
  produce(state, draft => {
    switch (actions.type) {
      case types.SIDEBAR_VISIBILITY_TOGGLE:
        draft.isOpen = !state.isOpen;
        break;
      case types.SIDEBAR_VISIBILITY_SHOW:
        draft.isOpen = true;
        break;
      case types.SIDEBAR_VISIBILITY_HIDE:
        draft.isOpen = false;
        break;

      case types.SIDEBAR_STICKY_TOGGLE:
        draft.isSticky = !state.isSticky;
        break;
      case types.SIDEBAR_STICKY_ENABLE:
        draft.isSticky = true;
        break;
      case types.LAYOUT_BOXED_ENABLE:
      case types.LAYOUT_BOXED_TOGGLE:
      case types.SIDEBAR_STICKY_DISABLE:
        draft.isSticky = false;
        break;
      default: // for eslint
    }
  });

export default myReducer;
