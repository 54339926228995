import {
  FETCH,
  FETCH_SUCCESS,
  FETCH_ERROR,
  FETCH_ONE,
  FETCH_ONE_SUCCESS,
  FETCH_ONE_ERROR,
  CREATE,
  CREATE_SUCCESS,
  CREATE_ERROR,
  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_ERROR,
  DELETE,
  DELETE_SUCCESS,
  DELETE_ERROR,
  CLEAR_ACTION_STATUS,
  CLEAR_MODEL_DATA,
} from './actionTypes';

export const fetchCollection = (model, path, params) => {
  return {
    type: FETCH,
    meta: {
      success: FETCH_SUCCESS,
      failure: FETCH_ERROR,
      params: params,
      model: model,
    },
    payload: {
      path: path,
      params: params,
    },
  };
};

export const fetchRecord = (model, id, path, params) => {
  return {
    type: FETCH_ONE,
    meta: {
      success: FETCH_ONE_SUCCESS,
      failure: FETCH_ONE_ERROR,
      model: model,
      id: id,
    },
    payload: {
      path: path,
      params: params,
    },
  };
};

export const createRecord = (model, path, data, params, redirect = null) => {
  return {
    type: CREATE,
    meta: {
      success: CREATE_SUCCESS,
      failure: CREATE_ERROR,
      model: model,
      redirect,
    },
    payload: {
      path: path,
      data: data,
      params: params,
    },
  };
};

export const updateRecord = (model, id = null, path, data, params, redirect = null) => {
  return {
    type: UPDATE,
    meta: {
      success: UPDATE_SUCCESS,
      failure: UPDATE_ERROR,
      model,
      id,
      redirect: redirect,
    },
    payload: {
      path,
      data,
      params,
    },
  };
};

export const deleteRecord = (model, id, path, params, redirect = null) => {
  return {
    type: DELETE,
    meta: {
      success: DELETE_SUCCESS,
      failure: DELETE_ERROR,
      model,
      id,
      redirect,
    },
    payload: {
      path,
      params,
    },
  };
};

export const clearActionStatus = (model, action) => {
  return {
    type: CLEAR_ACTION_STATUS,
    payload: { model, action },
  };
};

export const clearModelData = model => {
  return {
    type: CLEAR_MODEL_DATA,
    payload: {
      model,
    },
  };
};
