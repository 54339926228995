const CRUD = `services/Crud`;

export const FETCH = `${CRUD}/FETCH`;

export const FETCH_SUCCESS = `${CRUD}/FETCH_SUCCESS`;
export const FETCH_ERROR = `${CRUD}/FETCH_ERROR`;
export const FETCH_ONE = `${CRUD}/FETCH_ONE`;
export const FETCH_ONE_SUCCESS = `${CRUD}/FETCH_ONE_SUCCESS`;
export const FETCH_ONE_ERROR = `${CRUD}/FETCH_ONE_ERROR`;
export const CREATE = `${CRUD}/CREATE`;
export const CREATE_SUCCESS = `${CRUD}/CREATE_SUCCESS`;
export const CREATE_ERROR = `${CRUD}/CREATE_ERROR`;
export const UPDATE = `${CRUD}/UPDATE`;
export const UPDATE_SUCCESS = `${CRUD}/UPDATE_SUCCESS`;
export const UPDATE_ERROR = `${CRUD}/UPDATE_ERROR`;
export const DELETE = `${CRUD}/DELETE`;
export const DELETE_SUCCESS = `${CRUD}/DELETE_SUCCESS`;
export const DELETE_ERROR = `${CRUD}/DELETE_ERROR`;
export const CLEAR_ACTION_STATUS = `${CRUD}/CLEAR_ACTION_STATUS`;
export const CLEAR_MODEL_DATA = `${CRUD}/CLEAR_MODEL_DATA`;

export const MESSAGES = {
  slotblockings: {
    create: {
      success: 'Successfully created blocked slot',
      fail: 'Cannot create blocked slot ',
    },
    delete: {
      success: 'Successfully deleted blocked slot',
      fail: 'Cannot delete blocked slot',
    },
  },
  appointments: {
    create: {
      success: 'Appointment created',
      fail: 'Cannot create appointment',
    },
    update: {
      success: 'Appointment updated',
      fail: 'Cannot update appointment',
    },
    delete: {
      success: 'Appointment deleted',
      fail: 'Cannot delete appointment',
    },
  },
  leavingcalendars: {
    create: {
      success: 'Successfully created leave calendar',
      fail: 'Cannot create leave calendar',
    },
    update: {
      success: 'Successfully updated leave calendar',
      fail: 'Cannot update leave calendar',
    },
    delete: {
      success: 'Successfully deleted leave calendar',
      fail: 'Cannot delete leave calendar',
    },
  },
  publicholidays: {
    create: {
      success: 'Successfully created public holiday',
      fail: 'Cannot create public holiday',
    },
    update: {
      success: 'Successfully updated public holiday',
      fail: 'Cannot update public holiday',
    },
    delete: {
      success: 'Successfully deleted public holiday',
      fail: 'Cannot delete public holiday',
    },
  },
  capacities: {
    update: {
      success: 'Successfully updated service centre capacity',
      fail: 'Cannot update service centre capacity',
    },
  },
  staffs: {
    create: {
      success: 'Employee created',
      fail: 'Cannot create employee',
    },
    update: {
      success: 'Successfully updated employee',
      fail: 'Cannot update employees',
    },
    delete: {
      success: 'Successfully deleted employee',
      fail: 'Cannot delete employees',
    },
  },
  promotions: {
    create: {
      success: 'Promotion created',
      fail: 'Cannot create promotion',
    },
    update: {
      success: 'Promotion updated',
      fail: 'Cannot update promotion',
    },
    delete: {
      success: 'Promotion deleted',
      fail: 'Cannot cancel promotion',
    },
  },
  accessories: {
    create: {
      success: 'Successfully create accessory',
      fail: 'Cannot create accessory',
    },
    update: {
      success: 'Successfully update accessory',
      fail: 'Cannot update accessory',
    },
    delete: {
      success: 'Successfully cancel accessory',
      fail: 'Cannot cancel accessory',
    },
  },
  discovers: {
    create: {
      success: 'Successfully create accessory',
      fail: 'Cannot create accessory',
    },
    update: {
      success: 'Successfully update accessory',
      fail: 'Cannot update accessory',
    },
    delete: {
      success: 'Successfully cancel accessory',
      fail: 'Cannot cancel accessory',
    },
  },
  settings: {
    update: {
      success: 'Successfully update TSA Image',
      fail: 'Cannot update TSA Image',
    },
  },
  carmodels: {
    create: {
      success: 'Successfully created model and its variant',
      fail: 'Cannot create  model and its variant',
    },
    update: {
      success: 'Successfully updated model and its variant',
      fail: 'Cannot update model and its variant',
    },
    delete: {
      success: 'Successfully deleted model and its variant',
      fail: 'Cannot delete model and its variant',
    },
  },
  categories: {
    create: {
      success: 'Successfully created category',
      fail: 'Cannot create category',
    },
    update: {
      success: 'Successfully updated category',
      fail: 'Cannot update category',
    },
    delete: {
      success: 'Successfully deleted category',
      fail: 'Cannot delete category',
    },
  },
  customers: {
    update: {
      success: 'Successfully updated customer',
      fail: 'Cannot update customer',
    },
  },
  devices: null,
  resend_email: {
    create: {
      success: 'Successfully sent email',
      fail: 'Cannot send email',
    },
  },
};
