import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { FETCH, FETCH_ONE, CREATE, UPDATE, DELETE, MESSAGES } from './actionTypes';

import { toastr } from 'react-redux-toastr';
import { push } from 'connected-react-router';
import { fetchData, createData, deleteData, updateData, fetchOneData } from './api';

export function* fetch(action) {
  const { success, failure } = action.meta;
  try {
    const resp = yield call(fetchData, action.payload);
    yield put({ meta: action.meta, type: success, payload: resp.json });
  } catch (error) {
    yield call(toastr.error, error.message || 'Cannot fetch data');
    yield put({ meta: action.meta, type: failure, payload: error, error: true });
  }
}

export function* fetchOne(action) {
  const { success, failure } = action.meta;
  try {
    const resp = yield call(fetchOneData, action.payload, action.meta.id);
    yield put({ meta: action.meta, type: success, payload: resp.json });
  } catch (error) {
    yield call(toastr.error, error.message || 'Cannot fetch data');
    yield put({ meta: action.meta, type: failure, payload: error, error: true });
  }
}

export function* create(action) {
  const { success, failure, redirect, model } = action.meta;
  let message = {};
  if (MESSAGES[model] && MESSAGES[model].create) {
    Object.assign(message, MESSAGES[model].create);
  } else {
    Object.assign(message, {
      success: 'Successfully created data',
      fail: 'Cannot create data',
    });
  }
  try {
    const resp = yield call(createData, action.payload);
    yield put({ meta: action.meta, type: success, payload: resp.json });
    if (redirect) {
      yield put(push(redirect));
    }
    yield call(toastr.success, message.success);
  } catch (error) {
    yield call(toastr.error, message.fail + ': ' + error.message);
    yield put({ meta: action.meta, type: failure, payload: error, error: true });
  }
}

export function* update(action) {
  const { success, failure, redirect, model } = action.meta;
  const message = {};
  let showMes = true;
  if (MESSAGES[model] === null) {
    showMes = false;
  } else if (MESSAGES[model] && MESSAGES[model].update) {
    Object.assign(message, MESSAGES[model].update);
  } else {
    Object.assign(message, {
      success: 'Successfully updated data ',
      fail: 'Cannot update data.',
    });
  }
  try {
    const resp = yield call(updateData, action.payload, action.meta.id);
    yield put({ meta: action.meta, type: success, payload: resp.json });
    if (redirect) {
      yield put(push(redirect));
    }
    if (showMes) {
      yield call(toastr.success, message.success);
    }
  } catch (error) {
    yield call(toastr.error, message.fail + ': ' + error.message);
    yield put({ meta: action.meta, type: failure, payload: error, error: true });
  }
}

export function* deleteRecord(action) {
  const { success, failure, redirect, model } = action.meta;
  let message = {};
  if (MESSAGES[model] && MESSAGES[model].delete) {
    Object.assign(message, MESSAGES[model].delete);
  } else {
    Object.assign(message, {
      success: 'Successfully deleted data ',
      fail: 'Cannot delete data',
    });
  }
  try {
    yield call(deleteData, action.payload, action.meta.id);
    yield put({ meta: action.meta, type: success, payload: action.meta.id });
    if (redirect) {
      yield put(push(redirect));
    }
    yield call(toastr.success, message.success);
  } catch (error) {
    yield call(toastr.error, message.fail + ': ' + error.message);
    yield put({ meta: action.meta, type: failure, payload: error, error: true });
  }
}

export default function* main() {
  yield takeEvery(FETCH, fetch);
  yield takeEvery(FETCH_ONE, fetchOne);
  yield takeEvery(CREATE, create);
  yield takeLatest(UPDATE, update);
  yield takeEvery(DELETE, deleteRecord);
}
