import {
  USER_LOGIN,
  USER_LOGIN_ERROR,
  USER_LOGIN_SUCCESS,
  FETCH_START,
  FETCH_END,
  FETCH_ERROR,
  FETCH_CANCEL,
} from '../constants';

const initialState = 0;

const loadingReducer = (state = initialState, { type }) => {
  switch (type) {
    case FETCH_START:
    case USER_LOGIN:
      return state + 1;
    case FETCH_END:
    case FETCH_ERROR:
    case FETCH_CANCEL:
    case USER_LOGIN_SUCCESS:
    case USER_LOGIN_ERROR:
      return Math.max(state - 1, 0);
    default:
      return state;
  }
};

export default loadingReducer;
