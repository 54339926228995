import React from 'react';
import loadable from '@loadable/component';
import Loader from './Loader';
import { SM } from 'containers/App/constants';

const Async = loadable(
  props => {
    if (props.role && props.role.id === SM) {
      return import(`reports/${props.page}`);
    } else {
      return import(`containers/${props.page}`);
    }
  },
  {
    fallback: <Loader />,
  },
);

export default Async;
