import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { userCheck } from 'containers/App/actions';

/**
 * Restrict access to children to authenticated users
 *
 * Useful for Route components  used internally by Resource.
 * Use it to decorate your custom page components to require
 * authentication.
 *
 * Pass the `location` from the `routeParams` as `location` prop.
 * You can set additional `authParams` at will if your authProvider
 * requires it.
 */

const Authenticated = ({ location, children, userCheck, authParams, ...rest }) => {
  useEffect(() => {
    const checkAuthentication = () => {
      userCheck(authParams, location && location.pathname);
    };
    checkAuthentication();
  }, [authParams, location, userCheck]);

  return React.cloneElement(children, rest);
};

export default connect(null, { userCheck })(Authenticated);
