import request from 'utils/request';
import { call, put, takeLatest } from 'redux-saga/effects';
import { MASQUERADE, CHECK_MASQUERADE, MASQUERADE_OUT } from './actionTypes';

import { toastr } from 'react-redux-toastr';
import authProvider from 'utils/authProvider';
import { push } from 'connected-react-router';

import { loadUser } from 'containers/App/actions';

const api = payload => {
  let requestURL = `/cms/v1/masquerade`;
  const requestOpts = {
    method: 'POST',
    body: JSON.stringify(payload.params),
  };

  return request(requestURL, requestOpts);
};

export function* maqueradeS(action) {
  const { success, failure } = action.meta;
  try {
    const resp = yield call(api, action.payload);
    if (resp.json) {
      yield call(authProvider.setMasquerade);
      yield call(authProvider.removeToken);
      yield call(authProvider.setToken, resp.json);
      yield call(loadUser);
      yield put({ meta: action.meta, type: success, payload: resp.json });
      yield put(push('/'));
      yield call(toastr.success, 'Successfully masquerade');
    }
  } catch (error) {
    yield call(toastr.error, 'Cannot Fetch Data ' + error.message);
    yield put({ meta: action.meta, type: failure, payload: error, error: true });
  }
}

export function* checkMasquerade(action) {
  const { success, failure } = action.meta;
  try {
    const resp = yield call(authProvider.getMasquerade, action.payload);
    if (resp && resp.access_token) {
      yield put({ meta: action.meta, type: success });
    }
  } catch (error) {
    yield call(toastr.error, 'Cannot Fetch Data ' + error.message);
    yield put({ meta: action.meta, type: failure, payload: error, error: true });
  }
}

export function* masqueradeOut(action) {
  const { success, failure } = action.meta;
  try {
    const resp = yield call(authProvider.getMasquerade, action.payload);
    if (resp && resp.access_token) {
      yield call(authProvider.setToken, resp);
      yield call(authProvider.removeMasquerade);
      yield call(loadUser);
      yield put({ meta: action.meta, type: success });
      yield put(push('/'));
      yield call(toastr.success, 'Successfully back to admin account');
    }
  } catch (error) {
    yield call(toastr.error, 'Cannot Fetch Data ' + error.message);
    yield put({ meta: action.meta, type: failure, payload: error, error: true });
  }
}

export default function* main() {
  yield takeLatest(MASQUERADE, maqueradeS);
  yield takeLatest(CHECK_MASQUERADE, checkMasquerade);
  yield takeLatest(MASQUERADE_OUT, masqueradeOut);
}
