import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import Feedback from './Feedback';
import FormGroup from './FormGroup';
import { Label } from 'reactstrap';
import styled from 'styled-components';

export const CheckboxGroupContext = createContext();

export const useCheckboxGroup = name => {
  const { setFieldValue } = useFormikContext();
  const { name: groupName, groupOnChange, value = [], ...rest } = useContext(CheckboxGroupContext);

  const toggle = () => {
    const valueArray = [...value];

    const indexOfVal = valueArray.indexOf(name);

    if (indexOfVal === -1) {
      valueArray.push(name);
    } else {
      valueArray.splice(indexOfVal, 1);
    }

    setFieldValue(groupName, valueArray);

    if (groupOnChange) {
      groupOnChange(valueArray);
    }
  };

  return { toggle, value: value.includes(name), ...rest };
};

const CheckboxGroup = ({ name, children, onChange: groupOnChange, label, className = '', helpText, ...rest }) => {
  const [field, metadata] = useField(name);
  const classes = classNames(
    'form-control border-0 p-0 h-auto form-control-check ' + className,
    metadata.touched ? 'is-touched' : 'is-untouched',
    metadata.touched && metadata.error && 'is-invalid',
  );

  const legend = label ? <Label>{label}</Label> : '';

  return (
    <CheckboxGroupContext.Provider value={{ ...field, groupOnChange, metadata }}>
      <FormGroupStyle tag="fieldset" for={name} {...rest}>
        {legend}
        {helpText && (
          <div className="mb-3">
            <small>{helpText}</small>
          </div>
        )}
        <div className={classes} data-testid={`check-items-${name}`}>
          {children}
        </div>
        <Feedback name={name} />
      </FormGroupStyle>
    </CheckboxGroupContext.Provider>
  );
};

const FormGroupStyle = styled(FormGroup)`
  .form-control-check {
    display: flex;
    flex-wrap: wrap;
  }
`;
CheckboxGroup.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.node,
  onChange: PropTypes.func,
};

export default CheckboxGroup;
