import { all } from 'redux-saga/effects';

import auth from './auth';
import fetch from './fetch';

export default authProvider =>
  function* appSaga() {
    // TODO: use fork!?
    yield all([auth(authProvider)(), fetch()]);
  };
