import {
  MASQUERADE,
  MASQUERADE_SUCCESS,
  MASQUERADE_ERROR,
  CHECK_MASQUERADE,
  CHECK_MASQUERADE_ERROR,
  CHECK_MASQUERADE_SUCCESS,
  MASQUERADE_OUT,
  MASQUERADE_OUT_SUCCESS,
  MASQUERADE_OUT_ERROR,
} from './actionTypes';
const initialState = {};

const masqueradeReducer = (state = initialState, action) => {
  switch (action.type) {
    case MASQUERADE:
      return Object.assign({}, state, {
        isSuccess: false,
        isPending: true,
      });
    case MASQUERADE_SUCCESS:
      return Object.assign({}, state, {
        isMasquerade: true,
        isSuccess: true,
        isPending: false,
      });
    case MASQUERADE_ERROR:
      return Object.assign({}, state, {
        isMasquerade: false,
        isSuccess: false,
        isPending: false,
      });

    case CHECK_MASQUERADE:
      return Object.assign({}, state, {
        isMasquerade: false,
        isPending: true,
      });
    case CHECK_MASQUERADE_SUCCESS:
      return Object.assign({}, state, {
        isMasquerade: true,
        isPending: false,
      });
    case CHECK_MASQUERADE_ERROR:
      return Object.assign({}, state, {
        isMasquerade: false,
        isPending: false,
      });

    case MASQUERADE_OUT:
      return Object.assign({}, state, {
        isMasquerade: false,
        isSuccess: false,
        isOut: true,
        isPending: true,
      });
    case MASQUERADE_OUT_SUCCESS:
      return Object.assign({}, state, {
        isSuccess: false,
        isMasquerade: false,
        isPending: false,
        isOut: true,
      });
    case MASQUERADE_OUT_ERROR:
      return Object.assign({}, state, {
        isMasquerade: false,
        isPending: false,
        isSuccess: false,
        isOut: false,
      });
    default:
      return state;
  }
};

export default masqueradeReducer;
