import decodeJwt from 'jwt-decode';
import httpClient from './request';

const apiUrl = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX;
const tokenKey = 'audi.token';
const tokenDataKey = 'audi.token_data';

const masqueradeKey = 'masquerade.token';
const masqueradeDataKey = 'masquerade.token_data';

const uuidKey = 'audi.uuid';

// called when the user attempts to log in
const login = payload => {
  const reqOpts = {
    method: 'POST',
    body: JSON.stringify(payload),
  };

  return httpClient(`${apiUrl}/login`, reqOpts).then(resp => {
    const token = setToken(resp.json);
    if (token) {
      return Promise.resolve(token);
    }
    return Promise.reject('invalid token');
  });
};

// called when the user attempts to logout
const logout = () => {
  return Promise.resolve(removeToken());
};

// called when the API returns an error
const checkError = ({ status }) => {
  if (status === 401) {
    removeToken();
    return Promise.reject();
  }
  return Promise.resolve();
};

// called when the user navigates to a new location
const checkAuth = () => {
  return !!getToken() ? Promise.resolve() : Promise.reject();
};

const setMasquerade = () => {
  try {
    const token = getToken();
    const decodedToken = decodeJwt(token.access_token);
    localStorage.setItem(masqueradeDataKey, JSON.stringify(decodedToken));
    localStorage.setItem(masqueradeKey, JSON.stringify(token));
    return token;
  } catch (e) {
    // no big deal
  }
  return false;
};

const getMasquerade = () => {
  try {
    return JSON.parse(localStorage.getItem(masqueradeKey));
  } catch (e) {
    // no big deal
  }
  return false;
};

const removeMasquerade = () => {
  localStorage.removeItem(masqueradeKey);
  localStorage.removeItem(masqueradeDataKey);
};

export const setToken = token => {
  try {
    const decodedToken = decodeJwt(token.access_token);
    localStorage.setItem(tokenDataKey, JSON.stringify(decodedToken));
    localStorage.setItem(tokenKey, JSON.stringify(token));
    return token;
  } catch (e) {
    // no big deal
  }
  return false;
};

const getToken = () => {
  try {
    return JSON.parse(localStorage.getItem(tokenKey));
  } catch (e) {
    // no big deal
  }
  return false;
};

const removeToken = () => {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(tokenDataKey);
  localStorage.removeItem(uuidKey);
};

const getTokenData = () => {
  try {
    return JSON.parse(localStorage.getItem(tokenDataKey));
  } catch (e) {
    // no big deal
  }
  return false;
};

// get the current user details
const getUserDetails = token => {
  const reqOpts = {
    method: 'GET',
  };

  return httpClient(`${apiUrl}/v1/users/me`, reqOpts)
    .then(resp => Promise.resolve(resp.json))
    .catch(error => Promise.reject(error));
};

const forgotPassword = payload => {
  const reqOpts = {
    method: 'POST',
    body: JSON.stringify(payload),
  };

  return httpClient(`${apiUrl}/request-reset-password`, reqOpts)
    .then(resp => Promise.resolve(resp.json))
    .catch(error => Promise.reject(error));
};

const resetPassword = payload => {
  const reqOpts = {
    method: 'POST',
    body: JSON.stringify(payload),
  };

  return httpClient(`${apiUrl}/reset-password`, reqOpts)
    .then(resp => Promise.resolve(resp.json))
    .catch(error => Promise.reject(error));
};

export default {
  login,
  logout,
  checkError,
  checkAuth,
  setToken,
  getToken,
  getTokenData,
  removeToken,
  tokenKey,
  getUserDetails,
  setMasquerade,
  getMasquerade,
  removeMasquerade,
  forgotPassword,
  resetPassword,
};
