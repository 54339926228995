import React from 'react';
import { ReactSVG } from 'react-svg';

const AudiIcons = ({ icon, iconClass = 'audiicon-small', className }) => {
  return (
    <ReactSVG
      src={`${process.env.PUBLIC_URL}/images/svg/${icon}.svg`}
      afterInjection={(error, svg) => {
        if (error) {
          console.error(error);
          return;
        }
      }}
      beforeInjection={svg => {
        svg.classList.add(iconClass);
      }}
      evalScripts="always"
      fallback={() => <span>Error!</span>}
      loading={() => <span>Loading</span>}
      renumerateIRIElements={false}
      wrapper="span"
      className={`wrapper-class-name ${className ? className : ''}`}
    />
  );
};

export default AudiIcons;
