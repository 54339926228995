import request from 'utils/request';
import { stringify } from 'qs';

export const fetchOneData = (payload, id) => {
  return request(payload.path + `/${id}`);
};

export const createData = payload => {
  let requestURL = `${payload.path}`;

  const requestOpts = {
    method: 'POST',
    body: JSON.stringify(payload.data),
  };

  return request(requestURL, requestOpts);
};

export const updateData = (payload, id = null) => {
  const requestURL = id ? `${payload.path}/${id}` : `${payload.path}`;

  const requestOpts = {
    method: 'PATCH',
    body: JSON.stringify(payload.data),
  };

  return request(requestURL, requestOpts);
};

export const deleteData = (payload, id) => {
  const requestURL = id ? `${payload.path}/${id}` : payload.path;
  const requestOpts = {
    method: 'DELETE',
    body: JSON.stringify(payload.params),
  };
  return request(requestURL, requestOpts);
};

export const fetchData = ({ path, params }) => {
  const requestURL = path;
  const { sort = null, filter, page, sizePerPage } = params;
  const query = stringify(
    {
      ...sort,
      f: JSON.stringify(filter),
      p: page,
      l: sizePerPage,
    },
    { addQueryPrefix: true },
  );
  return request(requestURL + query);
};
