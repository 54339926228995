import produce from 'immer';

import { USER_LOGIN_SUCCESS, USER_LOGOUT, LOAD_USER_SUCCESS, LOAD_USER_ERROR } from '../constants';
import authProvider from 'utils/authProvider';

// load token object from localStorage if exists
const token = authProvider.getToken();
const tokenData = authProvider.getTokenData();

export const initialState = {
  token,
  user: { ...tokenData, fromCache: true },
};

const myReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case USER_LOGIN_SUCCESS:
        draft.token = payload;
        break;
      case USER_LOGOUT:
        draft.token = false;
        draft.user = false;
        break;
      case LOAD_USER_SUCCESS:
        draft.user = payload;
        break;
      case LOAD_USER_ERROR:
        draft.user = false;
        break;
      default: // for eslint
    }
  });

export default myReducer;
