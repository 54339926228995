import { combineReducers } from 'redux';

import auth from './auth';
import loading from './loading';
import layout from './layout';
import sidebar from './sidebar';
import theme from './theme';

export default combineReducers({
  loading,
  auth,
  layout,
  sidebar,
  theme,
});
