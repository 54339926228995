/**
 * Authentication saga
 */

import { call, all, put, select, takeEvery, takeLatest, cancelled } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import {
  USER_LOGIN,
  USER_CHECK,
  USER_LOGOUT,
  FETCH_ERROR,
  LOAD_USER,
  USER_FORGOT_PASSWORD,
  USER_RESET_PASSWORD,
} from 'containers/App/constants';
import {
  userLogout,
  userLoginSuccess,
  userLoginError,
  loadUser,
  loadUserError,
  loadUserSuccess,
  loadUserCancelled,
} from 'containers/App/actions';
import { makeSelectNextPathname, makeSelectUser } from 'containers/App/selectors';
import { toastr } from 'react-redux-toastr';

export default authProvider => {
  if (!authProvider) {
    return () => null;
  }

  function* handleAuth(action) {
    const { type, payload, error, meta = {} } = action;
    switch (type) {
      case LOAD_USER: {
        try {
          const userDetails = yield call(authProvider.getUserDetails);
          yield put(loadUserSuccess(userDetails));
        } catch (err) {
          yield put(loadUserError(err));
        } finally {
          if (yield cancelled()) {
            yield put(loadUserCancelled());
          }
        }
        break;
      }
      case USER_LOGIN: {
        try {
          const authResp = yield call(authProvider.login, payload);
          yield put(userLoginSuccess(authResp));
          const redirectTo = yield meta.pathName || select(makeSelectNextPathname());
          yield put(push(redirectTo || '/'));
        } catch (err) {
          yield call(toastr.error, 'Can not login: ', err.message);
          yield put(userLoginError(err));
        }
        break;
      }
      case USER_CHECK: {
        try {
          yield call(authProvider.checkAuth, payload);
          const user = yield select(makeSelectUser());
          if (!user || user.fromCache) {
            yield put(loadUser());
          }
        } catch (e) {
          yield call(authProvider.logout);
          yield put(
            replace({
              pathname: (e && e.redirectTo) || '/login',
              state: { nextPathname: meta.pathName },
            }),
          );
        }
        break;
      }
      case USER_LOGOUT: {
        yield call(authProvider.logout);
        yield put(push((action.payload && action.payload.redirectTo) || '/login'));
        break;
      }
      case USER_FORGOT_PASSWORD: {
        try {
          yield call(authProvider.forgotPassword, payload);
          yield call(toastr.success, 'Reset Password has been sent to your email.');
        } catch (e) {
          yield call(toastr.error, 'Can not reset password: ' + e.message);
        }
        break;
      }
      case FETCH_ERROR:
        try {
          yield call(authProvider.checkError, error);
        } catch (e) {
          const nextPathname = yield select(makeSelectNextPathname());
          yield put(userLogout({ redirectTo: nextPathname }));
        }
        break;

      case USER_RESET_PASSWORD:
        try {
          yield call(authProvider.resetPassword, payload);
          yield call(toastr.success, 'Password has been reset successfully.');
          yield put(push((action.payload && action.payload.redirectTo) || '/login'));
        } catch (e) {
          yield call(toastr.error, 'Can not reset password: ' + e.message);
        }
        break;
      default:
        break;
    }
  }

  return function* watchAuthActions() {
    yield all([
      takeEvery([USER_LOGIN, USER_CHECK, USER_LOGOUT], handleAuth),
      takeLatest(FETCH_ERROR, handleAuth),
      takeLatest(LOAD_USER, handleAuth),
      takeLatest(USER_FORGOT_PASSWORD, handleAuth),
      takeLatest(USER_RESET_PASSWORD, handleAuth),
    ]);
  };
};
