import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import AudiIcons from 'components/Commons/AudiIcons/index';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { masqueradeOut } from './actions';

import sagaMaquerade from './sagas';
import reducerMaquerade from './reducers';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { makeSelectMaquerade } from './selectors';

import withPermissions from 'utils/permissions';

const MasqueradeLogout = ({ masqueradeOut }) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <Button className="py-3 text-center masquerade-div" onClick={() => toggle()}>
        <AudiIcons icon="icons-menu-masquarade" />
      </Button>
      <Modal isOpen={modal} toggle={toggle} centered size="lg">
        <ModalHeader toggle={toggle}>Exit masquerade</ModalHeader>
        <ModalBody>Are you sure you want to return to admin view ? </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" className="mr-2" onClick={() => toggle()}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => masqueradeOut()}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const withReducerMaquerade = injectReducer({ key: 'masquerade', reducer: reducerMaquerade });
const withSagaMaquerade = injectSaga({ key: 'masquerade', saga: sagaMaquerade });

const mapStateToProps = createStructuredSelector({
  masqueradeStt: makeSelectMaquerade(),
});

const mapDispatchToProps = {
  masqueradeOut,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withPerm = withPermissions({
  perform: 'staff:delete',
});

export default compose(withRouter, withConnect, withPerm, withReducerMaquerade, withSagaMaquerade)(MasqueradeLogout);
