import authProvider, { setToken } from 'utils/authProvider';

let isRefreshingToken = false;
let refreshTokenPromise = null;

class HttpError extends Error {
  constructor(message, status, body = null) {
    super(message);
    this.status = status;
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
    this.stack = new Error().stack;
  }
}

export const baseURL = process.env.REACT_APP_API_URL;
export const basePath = `${process.env.REACT_APP_API_PREFIX}/${process.env.REACT_APP_API_VERSION}`;
export const apiURI = baseURL + basePath;

const handleUnAuthorizationRequest = (token, originRequest) => {
  if (!token) {
    return Promise.reject(new HttpError('Unauthorized', 401));
  }

  const refreshToken = token.refresh_token;

  if (!refreshToken) {
    return Promise.reject(new HttpError('Unauthorized', 401));
  }

  if (!isRefreshingToken) {
    isRefreshingToken = true;
    refreshTokenPromise = doRequest(`${baseURL}/cms/refresh-token`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ refresh_token: refreshToken }),
    });
  }

  return refreshTokenPromise
    .then(response => {
      if (response.status === 200 && response.json) {
        setToken(response.json);
        isRefreshingToken = false;
        refreshTokenPromise = null;

        const { url, options } = originRequest;

        return doRequest(url, options);
      }
    })
    .catch(error => {
      isRefreshingToken = false;
      refreshTokenPromise = null;
      return Promise.reject(error);
    });
};

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
const doRequest = (url, options = {}) => {
  if (!url) {
    return Promise.reject('url is required!');
  }

  let requestURI = url;
  if (!url.startsWith('http') && !url.startsWith('//')) {
    if (url.startsWith('/')) {
      requestURI = baseURL + url;
    } else {
      requestURI = apiURI + '/' + url;
    }
  }

  if (!options.headers) {
    options.headers = new Headers();
  }

  if (!options.headers.has('Accept')) {
    options.headers.set('Accept', 'application/json');
  }
  if (!options.headers.has('Content-Type') && !(options.body && options.body instanceof FormData)) {
    options.headers.set('Content-Type', 'application/json');
  }

  const token = authProvider.getToken();
  if (token) {
    options.headers.set('Authorization', `Bearer ${token.access_token}`);
  }
  return fetch(requestURI, options)
    .then(response => {
      return response.text().then(text => {
        return {
          status: response.status,
          statusText: response.statusText,
          headers: response.headers,
          body: text,
        };
      });
    })
    .then(({ status, statusText, headers, body }) => {
      let json;
      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        if (status === 400 && url === `${baseURL}/cms/refresh-token`) {
          authProvider.removeToken();
          window.location = '/login';

          return Promise.reject(new HttpError((json && json.error && json.error.message) || statusText, status, json));
        }

        if (status === 401) {
          return handleUnAuthorizationRequest(token, {
            url: url,
            options: options,
          });
        }
        return Promise.reject(new HttpError((json && json.error && json.error.message) || statusText, status, json));
      }
      return Promise.resolve({ status, headers, body, json });
    });
};

export default doRequest;
