import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import AudiIcons from 'components/Commons/AudiIcons';
import styled from 'styled-components';
import setMinutes from 'date-fns/setMinutes';
import setHours from 'date-fns/setHours';
import { useField } from 'formik';
import classNames from 'classnames';

const DPicker = ({
  selected,
  onChange,
  name,
  minDate,
  maxDate,
  minTime,
  maxTime,
  showTimeSelect,
  showTimeSelectOnly,
  // dateFormat,
  required,
  className,
  disabled,
  placeholderText,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [field, metadata] = useField(name);

  const onChangeInput = date => {
    if (Object.prototype.toString.call(date) !== '[object Date]') return;
    if (!showTimeSelectOnly) {
      onChange(moment(date).format('Y-MM-DD'), name);
    } else {
      onChange(moment(date).format('HH:mm'), name);
    }
  };

  const classes = classNames(
    className,
    metadata.touched ? 'is-touched' : 'is-untouched',
    metadata.error ? 'is-invalid' : 'av-valid',
    metadata.touched && metadata.error && 'is-invalid',
  );

  return (
    <Div className={`position-relative ${showTimeSelectOnly ? 'time' : 'date'}`}>
      <DatePicker
        disabled={disabled}
        name={name}
        required={required}
        className={classes}
        selected={selected ? new Date(selected) : ''}
        dateFormat={'dd/MM/y'}
        onChange={date => onChangeInput(date)}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        minTime={minTime ? setHours(setMinutes(new Date(), minTime[1]), minTime[0]) : null}
        maxTime={maxTime ? setHours(setMinutes(new Date(), maxTime[1]), maxTime[0]) : null}
        minDate={minDate ? new Date(minDate) : ''}
        maxDate={maxDate ? new Date(maxDate) : ''}
        placeholderText={placeholderText}
      />
      {metadata.error && (
        <div className="err-msg">
          <small className="text-danger">{metadata.error}</small>
        </div>
      )}
      {!showTimeSelectOnly && <AudiIconsStyle icon="icon-input-calendar" />}
    </Div>
  );
};

const Div = styled.div`
  .err-msg {
    position: absolute;
    bottom: -26px;
  }
`;

const AudiIconsStyle = styled(AudiIcons)`
  position: absolute;
  right: 11px;
  top: 5px;
`;

export default DPicker;
