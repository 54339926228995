import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createSelector } from 'reselect';
import { createStructuredSelector } from 'reselect';

import Wrapper from 'components/Commons/Wrapper';
import Main from 'components/Commons/Main';

import Sidebar from 'components/Commons/Sidebar/index';
import Content from 'components/Commons/Content';
import { AuthProvider } from 'services/Rbac/authContext';

const Dashboard = ({ children, currentUser }) => {
  const [authProviderValue, setAuthProviderValue] = useState({});

  useEffect(() => {
    setAuthProviderValue({
      ...currentUser.user,
    });
  }, [currentUser]);

  return (
    <AuthProvider value={authProviderValue}>
      <Wrapper>
        <Sidebar />
        <Main>
          <Content>{children}</Content>
        </Main>
      </Wrapper>
    </AuthProvider>
  );
};
const selectAuth = state => (state && state['global'] && state['global'].auth) || false;

const makeLoggedInUser = () => createSelector(selectAuth, state => state);

const mapStateToProps = createStructuredSelector({
  currentUser: makeLoggedInUser(),
});

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, withConnect)(Dashboard);
