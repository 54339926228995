import {
  MASQUERADE,
  MASQUERADE_ERROR,
  MASQUERADE_SUCCESS,
  CHECK_MASQUERADE,
  CHECK_MASQUERADE_SUCCESS,
  CHECK_MASQUERADE_ERROR,
  MASQUERADE_OUT,
  MASQUERADE_OUT_SUCCESS,
  MASQUERADE_OUT_ERROR,
} from './actionTypes';

export const masquerade = params => {
  return {
    type: MASQUERADE,
    meta: {
      success: MASQUERADE_SUCCESS,
      failure: MASQUERADE_ERROR,
      params: params,
    },
    payload: {
      params: params,
    },
  };
};

export const userMasqueradeCheck = () => {
  return {
    type: CHECK_MASQUERADE,
    meta: {
      success: CHECK_MASQUERADE_SUCCESS,
      failure: CHECK_MASQUERADE_ERROR,
    },
    payload: {},
  };
};

export const masqueradeOut = () => {
  return {
    type: MASQUERADE_OUT,
    meta: {
      success: MASQUERADE_OUT_SUCCESS,
      failure: MASQUERADE_OUT_ERROR,
    },
    payload: {},
  };
};
